import { defineStore } from 'pinia'

import router from '@/router/index'
import axios from '@/api/index'
import qs from 'qs'
import Nprogress from 'nprogress';
import  '../../node_modules/js-loading-overlay/dist/js-loading-overlay.min.js'
export const usesettingsStore = defineStore('settingsstore', {

    state: () => {
        return {
          sidebarDetails: [],
          generalConfigDetails: [],
          expressConfigDetails: [],
          miscellConfigDetails: [],
          tallyConfigDetails: [],
          dashConfigDetails: [],
          payConfigDetails: [],
          custPortURLConfigDetails: [],
          pmsSettingsDetails: [],
          franchiseSettingsDetails: [],
          adminWithAccessDetails: [],
          appRunTimeDetails:[],
          billingDetails: [],
          billingAddress: [],
          routerData: [],
          apiData: [],
          billingPeriodData: [],
          linkedInData: [],
          twitterData: [],
          facebookData: [],
          instagramData: [],
          googleplusData: [],
          invoicePrefixData: [],
          proformaInvoicePrefixData: [],
          paymentPrefixData: [],
          creditNotePrefixData: [],
          cafPrefixData: [],
          usernamePrefixData: [],
          circuitIdPrefixData: [],
          leadPrefixData: [],
          helpdeskPrefixData: [],
          approvalTaskData: [],
          payAsYouGoData: [],
          leadSettingsData: [],
          customRulesData: [],
          additionalFieldRulesData: [],
          adminSettingsData: [],
          profileSettingsData: [],
          paymentGatewayData: [],
          userFieldsData: [],
          tr069FieldsData:[],
          additionalFieldsData: [],
          smsManagementData: [],
          smsManagementCloudData: [],
          tableHeaderConfigData: [],
          reportVisibleColumnData:[],
          moveAccountsData: [],
          additionalFieldsData: [],
          workhrsData: [],
          consolidatedTemplateList: [],
          customRuleSampleFilePath: [],
        }
    },
    getters: {
      customRuleSampleFilePathState: (state) => state.customRuleSampleFilePath,
      getsampleCustomRuleSampleFilePath() {
          return this.customRuleSampleFilePathState;
      },
      sidebarDetailsState: (state) => state.sidebarDetails,
      sidebarDetailsGetter() {
        return this.sidebarDetailsState;
      },
      generalConfigDetailsState: (state) => state.generalConfigDetails,
      generalConfigGetter() {
        return this.generalConfigDetailsState;
      },
      tallyConfigDetailsState: (state) => state.tallyConfigDetails,
      tallyConfigGetter() {
        return this.tallyConfigDetails;
      },
      expressConfigDetailsState: (state) => state.expressConfigDetails,
      expressConfigGetter() {
        return this.expressConfigDetailsState;
      },
      miscellConfigDetailsState: (state) => state.miscellConfigDetails,
      miscellaneousConfigGetter() {
        return this.miscellConfigDetailsState;
      },
      dashConfigDetailsState: (state) => state.dashConfigDetails,
      dashboardConfigGetter() {
        return this.dashConfigDetailsState;
      },
      payConfigDetailsState: (state) => state.payConfigDetails,
      paymentConfigGetter() {
        return this.payConfigDetailsState;
      },
      custPortURLConfigDetailsState: (state) => state.custPortURLConfigDetails,
      custPortURLConfigGetter() {
        return this.custPortURLConfigDetailsState;
      },
      pmsSettingsDetailsState: (state) => state.pmsSettingsDetails,
      pmsSettingsGetter() {
        return this.pmsSettingsDetailsState;
      },
      franchiseSettingsDetailsState: (state) => state.franchiseSettingsDetails,
      franchiseSettingsGetter() {
        return this.franchiseSettingsDetailsState;
      },
      adminWithAccessDetailsState: (state) => state.adminWithAccessDetails,
      adminWithAccessGetter() {
        return this.adminWithAccessDetailsState;
      },
      appRunTimeDetailsState: (state) => state.appRunTimeDetails,
      appRunTimeGetter() {
        return this.appRunTimeDetailsState;
      },
      billingDetailsState: (state) => state.billingDetails,
      billingSettingsGetter() {
        return this.billingDetailsState;
      },
      billingAddressState: (state) => state.billingAddress,
      billingAddressGetter() {
        return this.billingAddressState;
      },
      routerDataState: (state) => state.routerData,
      routerSettingsGetter() {
        return this.routerDataState;
      },
      apiDataState: (state) => state.apiData,
      apiDataGetter() {
        return this.apiDataState;
      },
      billingPeriodDataState: (state) => state.billingPeriodData,
      billingPeriodGetter() {
        return this.billingPeriodDataState;
      },
      linkedInDataState: (state) => state.linkedInData,
      linkedInGetter() {
        return this.linkedInDataState;
      },
      twitterDataState: (state) => state.twitterData,
      twitterDataGetter() {
        return this.twitterDataState;
      },
      facebookDataState: (state) => state.facebookData,
      facebookDataGetter() {
        return this.facebookDataState;
      },
      instagramDataState: (state) => state.instagramData,
      instagramDataGetter() {
        return this.instagramDataState;
      },
      googleplusDataState: (state) => state.googleplusData,
      googlePlusGetter() {
        return this.googleplusDataState;
      },
      invoicePrefixDataState: (state) => state.invoicePrefixData,
      invoicePrefixGetter() {
        return this.invoicePrefixDataState;
      },
      proformaInvoicePrefixDataState: (state) => state.proformaInvoicePrefixData,
      proformaInvoiceGetter() {
        return this.proformaInvoicePrefixDataState;
      },
      paymentPrefixDataState: (state) => state.paymentPrefixData,
      paymentPrefixGetter() {
        return this.paymentPrefixDataState;
      },
      creditNotePrefixDataState: (state) => state.creditNotePrefixData,
      creditNotePrefixGetter() {
        return this.creditNotePrefixDataState;
      },
      cafPrefixDataState: (state) => state.cafPrefixData,
      cafPrefixGetter() {
        return this.cafPrefixDataState;
      },
      usernamePrefixDataState: (state) => state.usernamePrefixData,
      usernamePrefixGetter() {
        return this.usernamePrefixDataState;
      },
      circuitIdPrefixDataState: (state) => state.circuitIdPrefixData,
      circuitIdPrefixGetter() {
        return this.circuitIdPrefixDataState;
      },
      leadPrefixDataState: (state) => state.leadPrefixData,
      leadPrefixGetter() {
        return this.leadPrefixDataState;
      },
      helpdeskPrefixDataState: (state) => state.helpdeskPrefixData,
      helpdeskPrefixGetter() {
        return this.helpdeskPrefixDataState;
      },
      approvalTaskDataState: (state) => state.approvalTaskData,
      approvalTasksGetter() {
        return this.approvalTaskDataState;
      },
      payAsYouGoDataState: (state) => state.payAsYouGoData,
      payAsYouGoGetter() {
        return this.payAsYouGoDataState;
      },
      leadSettingsDataState: (state) => state.leadSettingsData,
      leadSettingsGetter() {
        return this.leadSettingsDataState;
      },
      customRulesDataState: (state) => state.customRulesData,
      customRulesGetter() {
        return this.customRulesDataState;
      },
      adminSettingsDataState: (state) => state.adminSettingsData,
      adminSettingsGetter() {
        return this.adminSettingsDataState;
      },
      profileSettingsDataState: (state) => state.profileSettingsData,
      profileSettingsGetter() {
        return this.profileSettingsDataState;
      },
      paymentGatewayDataState: (state) => state.paymentGatewayData,
      paymentGatewaySettingsGetter() {
        return this.paymentGatewayDataState;
      },
      userFieldsDataState: (state) => state.userFieldsData,
      userFieldSettingsGetter() {
        return this.userFieldsDataState;
      },
      tr069FieldsDataState: (state) => state.tr069FieldsData,
      tr069FieldSettingsGetter() {
        return this.tr069FieldsDataState;
      },      
      additionalFieldsDataState: (state) => state.additionalFieldsData,
      additionalFieldsGetter() {
        return this.additionalFieldsDataState;
      },
      smsManagementDataState: (state) => state.smsManagementData,
      smsManagementGetter() {
        return this.smsManagementDataState;
      },
      smsManagementCloudDataState: (state) => state.smsManagementCloudData,
      cloudSmsManagementGetter() {
        return this.smsManagementCloudDataState;
      },
      tableHeaderConfigDataState: (state) => state.tableHeaderConfigData,
      tableHeaderConfigGetter() {
        return this.tableHeaderConfigDataState;
      },
      reportVisibleColumnDataState: (state) => state.reportVisibleColumnData,
      reportVisibleColumnConfigGetter() {
        return this.reportVisibleColumnDataState;
      },
      moveAccountsDataState: (state) => state.moveAccountsData,
      moveAccountsGetter() {
        return this.moveAccountsDataState;
      },
      additionalFieldRulesDataState: (state) => state.additionalFieldRulesData,
      additionalFieldRulesGetter() {
        return this.additionalFieldRulesDataState;
      },
      workhrsDataState: (state) => state.workhrsData,
      enableWorkingHrsGetters() {
        return this.workhrsDataState;
      },
      consolidatedTemplateListState: (state) => state.consolidatedTemplateList,
      getConsolidatedTemplateDetailsGetters() {
        return this.consolidatedTemplateListState;
      },
    },
    actions: {
        saveSubAccountPermissionSettings(postData) {
            return new Promise((resolve, reject) => {
              JsLoadingOverlay.show({
                    'spinnerIcon': 'ball-clip-rotate',
                    "overlayOpacity": "0.6",
                    "spinnerSize": "2x",
                    "lockScroll": true,
                });
              axios.post('/settings/save_sub_accounts_cannot_change_settings', qs.stringify(postData, {
                parseArrays: false
              }))
                .then(response => {
                  JsLoadingOverlay.hide();
                  if (response.data.status != 'success') {
                    reject(response.data.message);
                    return;
                  }
                  this.setCheckboxValue(postData);
                  resolve(response.data.genericBody);
                })
                .catch(error => console.log(error));
            });
        },
        setCheckboxValue(settingsData) {
            if (settingsData.type == 'miscellaneous') {
              if (settingsData.switchCheckBoxValue == 'on') {
                this.miscellConfigDetails['subAccountsCanNotChangeMiscConfiguration'] = 'Yes';
              } else {
                this.miscellConfigDetails['subAccountsCanNotChangeMiscConfiguration'] = 'No';
              }
            } else if (settingsData.type == 'tally') {
              if (settingsData.switchCheckBoxValue == 'on') {
                this.tallyConfigDetails['subAccountsCanNotChangeTallyInformation'] = 'Yes';
              } else {
                this.tallyConfigDetails['subAccountsCanNotChangeTallyInformation'] = 'No';
              }
            } else if (settingsData.type == 'dashboard') {
              if (settingsData.switchCheckBoxValue == 'on') {
                this.dashConfigDetails['subAccountsCanNotChangeDashboard'] = 'Yes';
              } else {
                this.dashConfigDetails['subAccountsCanNotChangeDashboard'] = 'No';
              }
            } else if (settingsData.type == 'paymentcollect') {
              if (settingsData.switchCheckBoxValue == 'on') {
                this.payConfigDetails['subAccountsCanNotChangePaymentCollection'] = 'Yes';
              } else {
                this.payConfigDetails['subAccountsCanNotChangePaymentCollection'] = 'No';
              }
            } else if (settingsData.type == 'billing') {
              if (settingsData.switchCheckBoxValue == 'on') {
                this.billingDetails['subAccountsCanNotChangeBillingSettings'] = 'Yes';
              } else {
                this.billingDetails['subAccountsCanNotChangeBillingSettings'] = 'No';
              }
            } else if (settingsData.type == 'billing_addr') {
              if (settingsData.switchCheckBoxValue == 'on') {
                this.billingAddress['subAccountsCanNotChangeBillingAddress'] = 'Yes';
              } else {
                this.billingAddress['subAccountsCanNotChangeBillingAddress'] = 'No';
              }
            } else if (settingsData.type == 'payAsYouGo') {
              if (settingsData.switchCheckBoxValue == 'on') {
                this.payAsYouGoData['subAccountsCanNotChangePayAsYouGoSettings'] = 'Yes';
              } else {
                this.payAsYouGoData['subAccountsCanNotChangePayAsYouGoSettings'] = 'No';
              }
            } else if (settingsData.type == 'lead') {
              if (settingsData.switchCheckBoxValue == 'on') {
                this.leadSettingsData['subAccountsCanNotChangeLeadSettings'] = 'Yes';
              } else {
                this.leadSettingsData['subAccountsCanNotChangeLeadSettings'] = 'No';
              }
            } else if (settingsData.type == 'userFields') {
              if (settingsData.switchCheckBoxValue == 'on') {
                this.userFieldsData['subAccountsCanNotChangeUserFieldsValues'] = 'Yes';
              } else {
                this.userFieldsData['subAccountsCanNotChangeUserFieldsValues'] = 'No';
              }
            } else if (settingsData.type == 'additionalFields') {
              if (settingsData.switchCheckBoxValue == 'on') {
                this.additionalFieldsData['subAccountsCanNotChangeAdditionalFieldsValues'] = 'Yes';
              } else {
                this.additionalFieldsData['subAccountsCanNotChangeAdditionalFieldsValues'] = 'No';
              }
            } else if (settingsData.type == 'tableHeader') {
              if (settingsData.switchCheckBoxValue == 'on') {
                this.tableHeaderConfigData['subAccountsCanNotChangeTableHeaderConfiguration'] = 'Yes';
              } else {
                this.tableHeaderConfigData['subAccountsCanNotChangeTableHeaderConfiguration'] = 'No';
              }
            } else if (settingsData.type == 'reportColumn') {
              if (settingsData.switchCheckBoxValue == 'on') {
                this.tableHeaderConfigData['subAccountsCanNotChangeReportColumnVisibleConfiguration'] = 'Yes';
              } else {
                this.tableHeaderConfigData['subAccountsCanNotChangeReportColumnVisibleConfiguration'] = 'No';
              }
            } else if (settingsData.type == 'custom_rules') {
              if (settingsData.switchCheckBoxValue == 'on') {
                this.billingAddress['subAccountsCanNotChangeCustomRules'] = 'Yes';
              } else {
                this.billingAddress['subAccountsCanNotChangeCustomRules'] = 'No';
              }
            }
        },
        clearSettingsState() {
          //commit('CLEAR_SETTINGS_STATE')
            this.generalConfigDetails = [];
            this.expressConfigDetails = [];
            this.miscellConfigDetails = [];
            this.tallyConfigDetails = [];
            this.dashConfigDetails = [];
            this.payConfigDetails = [];
            this.custPortURLConfigDetails = [];
            this.pmsSettingsDetails = [];
            this.franchiseSettingsDetails = [];
            this.adminWithAccessDetails = [];
            this.appRunTimeDetails=[];
            this.billingDetails = [];
            this.billingAddress = [];
            this.routerData = [];
            this.apiData = [];
            this.billingPeriodData = [];
            this.linkedInData = [];
            this.twitterData = [];
            this.facebookData = [];
            this.instagramData = [];
            this.googleplusData = [];
            this.invoicePrefixData = [];
            this.proformaInvoicePrefixData = [];
            this.paymentPrefixData = [];
            this.creditNotePrefixData = [];
            this.usernamePrefixData = [];
            this.circuitIdPrefixData = [];
            this.leadPrefixData = [];
            this.helpdeskPrefixData = [];
            this.approvalTaskData = [];
            this.payAsYouGoData = [];
            this.leadSettingsData = [];
            this.customRulesData = [];
            this.additionalFieldRulesData = [];
            this.adminSettingsData = [];
            this.profileSettingsData = [];
            this.paymentGatewayData = [];
            this.userFieldsData = [];
            this.tr069FieldsData = [];
            this.additionalFieldsData = [];
            this.smsManagementData = [];
            this.smsManagementCloudData = [];
            this.tableHeaderConfigData = [];
            this.reportVisibleColumnData = [];
            this.moveAccountsData = [];
            this.workhrsData = [];
            this.consolidatedTemplateList = [];
            this.customRuleSampleFilePath = [];
            this.cafPrefixData=[]
        },
        fetchSidebarDetails() {
          Nprogress.start()
          axios.get("/settings/settings_sidebar")
            .then(response => {
              Nprogress.done()
              this.sidebarDetails = response.data.message;
            })
            .catch(error => console.log(error))
        },
        fetchGeneralDetails() {
          Nprogress.start()
          axios.get("/settings/index/true")
            .then(response => {
              Nprogress.done()
              this.generalConfigDetails = response.data.message;
            })
            .catch(error => {
              if (error.response) {
                if (error.response.status == 403) {
                  window.location = "/dashboard";
                }
              }
            })
        },
        generalConfigurationSubmit(postData) {
          return new Promise((resolve, reject) => {
            JsLoadingOverlay.show({
                    'spinnerIcon': 'ball-clip-rotate',
                    "overlayOpacity": "0.6",
                    "spinnerSize": "2x",
                    "lockScroll": true,
                });
            axios.post('/settings/submit_form', qs.stringify(postData, {
              parseArrays: false
            }))
              .then(response => {
                JsLoadingOverlay.hide();;
                if (response.data.status != 'success') {
                  reject(response.data.message);
                  return;
                }
                resolve(response.data.message);
              })
              .catch(error => console.log(error));
          });
        },
        fetchExpressDetails() {
          Nprogress.start()
          axios.get("/settings/express_configuration/true")
            .then(response => {
              Nprogress.done()
              this.expressConfigDetails = response.data.message;
            })
            .catch(error => {
              if (error.response) {
                if (error.response.status == 403) {
                  window.location = "/dashboard";
                }
              }
            })
        },
        expressConfigurationSubmit(postData) {
          return new Promise((resolve, reject) => {
            JsLoadingOverlay.show({
                    'spinnerIcon': 'ball-clip-rotate',
                    "overlayOpacity": "0.6",
                    "spinnerSize": "2x",
                    "lockScroll": true,
                });
            axios.post('/settings/express_configuration_submit', qs.stringify(postData, {
              parseArrays: false
            }))
              .then(response => {
                JsLoadingOverlay.hide();;
                if (response.data.status != 'success') {
                  reject(response.data.message);
                  return;
                }
                resolve(response.data.message);
              })
              .catch(error => console.log(error));
          });
        },
        deleteVendorSubmit(postData) {
          return new Promise((resolve, reject) => {
            JsLoadingOverlay.show({
                    'spinnerIcon': 'ball-clip-rotate',
                    "overlayOpacity": "0.6",
                    "spinnerSize": "2x",
                    "lockScroll": true,
                });
            axios.post('/settings/delete_vendor_config', qs.stringify(postData, {
              parseArrays: false
            }))
              .then(response => {
                JsLoadingOverlay.hide();
                if (response.data.status != 'success') {
                  reject(response.data.message);
                  return;
                }
                var successData = {
                  id: response.data.vendorId,
                  type: postData.type
                }
                this.deleteVendorList(successData)
                resolve(response.data.message);
              })
              .catch(error => console.log(error));
          });
        },
        deleteVendorList(settingsData) {
          if (settingsData.type == 'express') {
            if (this.expressConfigDetails.editData.vendorTablesData != undefined && this.expressConfigDetails.editData.vendorTablesData.length != 0) {
              var vendor = this.expressConfigDetails.editData.vendorTablesData;
              for (var i = 0; i < vendor.length; i++) {
                var index = vendor.findIndex(vendor => vendor.VendorAttribute.id == settingsData.id);
                if (index != '-1') {
                  this.expressConfigDetails.editData.vendorTablesData.splice(index, 1);
                }
              }
            }
          } else if (settingsData.type == 'billing') {
            if (this.billingDetails.editData.vendorTablesData != undefined && this.billingDetails.editData.vendorTablesData.length != 0) {
              var vendor = this.billingDetails.editData.vendorTablesData;
              for (var i = 0; i < vendor.length; i++) {
                var index = vendor.findIndex(vendor => vendor.VendorAttribute.id == settingsData.id);
                if (index != '-1') {
                  this.billingDetails.editData.vendorTablesData.splice(index, 1);
                }
              }
            }
          }          
        },
        zoneConfigSubmit(postData) {
          return new Promise((resolve, reject) => {
            JsLoadingOverlay.show({
                    'spinnerIcon': 'ball-clip-rotate',
                    "overlayOpacity": "0.6",
                    "spinnerSize": "2x",
                    "lockScroll": true,
                });
            axios.post('/settings/save_vendor_config', qs.stringify(postData, {
              parseArrays: false
            }))
              .then(response => {
                JsLoadingOverlay.hide();;
                if (response.data.status != 'success') {
                  reject(response.data.message);
                  return;
                }
                resolve(response.data.message);
              })
              .catch(error => console.log(error));
          });
        },
        fetchMiscellaneousDetails() {
          Nprogress.start()
          axios.get("/settings/miscellaneous_configuration/true")
            .then(response => {
              Nprogress.done()
              this.miscellConfigDetails = response.data.message;
            })
            .catch(error => {
              if (error.response) {
                if (error.response.status == 403) {
                  window.location = "/dashboard";
                }
              }
            })
        },
        miscellaneousConfigurationSubmit(postData) {
          return new Promise((resolve, reject) => {
            JsLoadingOverlay.show({
                    'spinnerIcon': 'ball-clip-rotate',
                    "overlayOpacity": "0.6",
                    "spinnerSize": "2x",
                    "lockScroll": true,
                });
            axios.post('/settings/miscellaneous_configuration_submit', postData, {
              headers: {
                "Content-Type": "multipart/form-data",
              },
            })
              .then(response => {
                JsLoadingOverlay.hide();;
                if (response.data.status != 'success') {
                  reject(response.data.message);
                  return;
                }
                resolve(response.data.message);
              })
              .catch(error => console.log(error));
          });
        },
        addMiscellaneousToAllSubZones(postData) {
          return new Promise((resolve, reject) => {
            JsLoadingOverlay.show({
                    'spinnerIcon': 'ball-clip-rotate',
                    "overlayOpacity": "0.6",
                    "spinnerSize": "2x",
                    "lockScroll": true,
                });
            axios.post('/settings/add_allto_miscellaneousConfiguration_subaccounts', qs.stringify(postData, {
              parseArrays: false
            }))
              .then(response => {
                JsLoadingOverlay.hide();
                if (response.data.status != 'success') {
                  reject(response.data.message);
                  return;
                }
                resolve(response.data.genericBody);
              })
              .catch(error => console.log(error));
          });
        },
        fetchBillingDetails() {
          Nprogress.start()
          axios.get("/settings/billing_settings/true")
            .then(response => {
              Nprogress.done()
              this.billingDetails = response.data.message;
            })
            .catch(error => {
              if (error.response) {
                if (error.response.status == 403) {
                  window.location = "/dashboard";
                }
              }
            })
        },
        billingSettingsSubmit(postData) {
          return new Promise((resolve, reject) => {
            JsLoadingOverlay.show({
                    'spinnerIcon': 'ball-clip-rotate',
                    "overlayOpacity": "0.6",
                    "spinnerSize": "2x",
                    "lockScroll": true,
                });
            axios.post('/settings/billing_settings_submit', qs.stringify(postData, {
              parseArrays: false
            }))
              .then(response => {
                JsLoadingOverlay.hide();;
                if (response.data.status != 'success') {
                  reject(response.data.message);
                  return;
                }
                resolve(response.data.message);
              })
              .catch(error => console.log(error));
          });
        },
        addBillingToAllSubZones(postData) {
          return new Promise((resolve, reject) => {
            JsLoadingOverlay.show({
                    'spinnerIcon': 'ball-clip-rotate',
                    "overlayOpacity": "0.6",
                    "spinnerSize": "2x",
                    "lockScroll": true,
                });
            axios.post('/settings/add_allto_billingSettings_subaccounts', qs.stringify(postData, {
              parseArrays: false
            }))
              .then(response => {
                JsLoadingOverlay.hide();
                if (response.data.status != 'success') {
                  reject(response.data.message);
                  return;
                }
                resolve(response.data.genericBody);
              })
              .catch(error => console.log(error));
          });
        },
        fetchBillingAddress() {
          Nprogress.start()
          axios.get("/settings/billing_address/true")
            .then(response => {
              Nprogress.done()
              this.billingAddress = response.data.message;
            })
            .catch(error => {
              if (error.response) {
                if (error.response.status == 403) {
                  window.location = "/dashboard";
                }
              }
            })
        },
        billingAddressSubmit(postData) {
          return new Promise((resolve, reject) => {
            JsLoadingOverlay.show({
                    'spinnerIcon': 'ball-clip-rotate',
                    "overlayOpacity": "0.6",
                    "spinnerSize": "2x",
                    "lockScroll": true,
                });
            axios.post('/settings/submit_billing_address_form', qs.stringify(postData, {
              parseArrays: false
            }))
              .then(response => {
                JsLoadingOverlay.hide();;
                if (response.data.status != 'success') {
                  reject(response.data.message);
                  return;
                }
                resolve(response.data.message);
              })
              .catch(error => console.log(error));
          });
        },
        addBillingAddrToAllSubZones(postData) {
          return new Promise((resolve, reject) => {
            JsLoadingOverlay.show({
                    'spinnerIcon': 'ball-clip-rotate',
                    "overlayOpacity": "0.6",
                    "spinnerSize": "2x",
                    "lockScroll": true,
                });
            axios.post('/settings/add_billing_address_to_subAcc', qs.stringify(postData, {
              parseArrays: false
            }))
              .then(response => {
                JsLoadingOverlay.hide();
                if (response.data.status != 'success') {
                  reject(response.data.message);
                  return;
                }
                resolve(response.data.genericBody);
              })
              .catch(error => console.log(error));
          });
        },
        fetchRouterData() {
          Nprogress.start()
          axios.get("/settings/router_settings/true")
            .then(response => {
              Nprogress.done()
              this.routerData = response.data.message;
            })
            .catch(error => {
              if (error.response) {
                if (error.response.status == 403) {
                  window.location = "/dashboard";
                }
              }
            })
        },
        routerSettingsSubmit(postData) {
          return new Promise((resolve, reject) => {
            JsLoadingOverlay.show({
                    'spinnerIcon': 'ball-clip-rotate',
                    "overlayOpacity": "0.6",
                    "spinnerSize": "2x",
                    "lockScroll": true,
                });
            axios.post('/settings/router_settings_submit', qs.stringify(postData, {
              parseArrays: false
            }))
              .then(response => {
                JsLoadingOverlay.hide();;
                if (response.data.status != 'success') {
                  reject(response.data.message);
                  return;
                }
                this.routerData['subAccountMergeExtraArr'] = response.data.subAccountMergeExtraArr;
                resolve(response.data.message);
              })
              .catch(error => console.log(error));
          });
        },
        fetchApiDetails() {
          Nprogress.start()
          axios.get("/settings/apis/true")
            .then(response => {
              Nprogress.done()
              this.apiData = response.data.message;
            })
            .catch(error => {
              if (error.response) {
                if (error.response.status == 403) {
                  window.location = "/dashboard";
                }
              }
            })
        },
        fetchBillingPeriodData() {
          Nprogress.start()
          axios.get("/settings/billing_period/true")
            .then(response => {
              Nprogress.done()
              this.billingPeriodData = response.data.message;
            })
            .catch(error => {
              if (error.response) {
                if (error.response.status == 403) {
                  window.location = "/dashboard";
                }
              }
            })
        },
        addBillingPeriodSubmit(postData) {
          return new Promise((resolve, reject) => {
            JsLoadingOverlay.show({
                    'spinnerIcon': 'ball-clip-rotate',
                    "overlayOpacity": "0.6",
                    "spinnerSize": "2x",
                    "lockScroll": true,
                });
            axios.post('/settings/add_billing_period/true', qs.stringify(postData, {
              parseArrays: false
            }))
              .then(response => {
                JsLoadingOverlay.hide();
                if (response.data.status != 'success') {
                  reject(response.data.message);
                  return;
                }
                var successData = {
                  id: response.data.periodAdded.id,
                  entityId: response.data.periodAdded.account_id,
                  periodUnitId: response.data.periodAdded.unit_id,
                  value: response.data.periodAdded.value,
                }
                if (Object.keys(this.billingPeriodData).length != 0) {
                  this.billingPeriodData.periods.push(successData);
                }
                resolve(response.data.message);
              })
              .catch(error => console.log(error));
          });
        },
        deleteBillingPeriodSubmit(postData) {
          return new Promise((resolve, reject) => {
            JsLoadingOverlay.show({
                    'spinnerIcon': 'ball-clip-rotate',
                    "overlayOpacity": "0.6",
                    "spinnerSize": "2x",
                    "lockScroll": true,
                });
            axios.post('/settings/delete_billing_period/true', qs.stringify(postData, {
              parseArrays: false
            }))
              .then(response => {
                JsLoadingOverlay.hide();
                if (response.data.status != 'success') {
                  reject(response.data.message);
                  return;
                }
                var index = "";
                if (Object.keys(this.billingPeriodData).length != 0 && Object.keys(this.billingPeriodData.periods).length != 0) {
                  for (var i = 0; i < this.billingPeriodData.periods.length; i++) {
                    if (this.billingPeriodData.periods[i].id == postData.periodId) {
                      index = i;
                    }
                  }
                  this.billingPeriodData.periods.splice(index, 1)
                }                
                resolve(response.data.message);
              })
              .catch(error => console.log(error));
          });
        },
        fetchinvoicePrefixData() {
          Nprogress.start()
          axios.get("/settings/invoice_prefix/true")
            .then(response => {
              Nprogress.done()
              this.invoicePrefixData = response.data.message;
            })
            .catch(error => {
              if (error.response) {
                if (error.response.status == 403) {
                  window.location = "/dashboard";
                }
              }
            })
        },
        fetchProformaInvoiceData() {
          Nprogress.start()
          axios.get("/settings/proforma_invoice_prefix/true")
            .then(response => {
              Nprogress.done()
              this.proformaInvoicePrefixData = response.data.message;
            })
            .catch(error => {
              if (error.response) {
                if (error.response.status == 403) {
                  window.location = "/dashboard";
                }
              }
            })
        },
        fetchPaymentPrefixData() {
          Nprogress.start()
          axios.get("/settings/payment_prefix/true")
            .then(response => {
              Nprogress.done()
              this.paymentPrefixData = response.data.message;
            })
            .catch(error => {
              if (error.response) {
                if (error.response.status == 403) {
                  window.location = "/dashboard";
                }
              }
            })
        },
        fetchUsernamePrefixData() {
          Nprogress.start()
          axios.get("/settings/username_prefix/true")
            .then(response => {
              Nprogress.done()
              this.usernamePrefixData = response.data.message;
            })
            .catch(error => {
              if (error.response) {
                if (error.response.status == 403) {
                  window.location = "/dashboard";
                }
              }
            })
        },
        fetchCircuitIdPrefixData() {
          Nprogress.start()
          axios.get("/settings/circuitid_prefix/true")
            .then(response => {
              Nprogress.done()
              this.circuitIdPrefixData = response.data.message;
            })
            .catch(error => {
              if (error.response) {
                if (error.response.status == 403) {
                  window.location = "/dashboard";
                }
              }
            })
        },
        fetchLeadPrefixData() {
          Nprogress.start()
          axios.get("/settings/lead_prefix/true")
            .then(response => {
              Nprogress.done()
              this.leadPrefixData = response.data.message;
            })
            .catch(error => {
              if (error.response) {
                if (error.response.status == 403) {
                  window.location = "/dashboard";
                }
              }
            })
        },
        fetchHelpdeskPrefixData() {
          Nprogress.start()
          axios.get("/settings/helpdesk_prefix/true")
            .then(response => {
              Nprogress.done()
              this.helpdeskPrefixData = response.data.message;
            })
            .catch(error => {
              if (error.response) {
                if (error.response.status == 403) {
                  window.location = "/dashboard";
                }
              }
            })
        },
        fetchCreditNotePrefixData() {
          Nprogress.start()
          axios.get("/settings/credit_note_prefix/true")
            .then(response => {
              Nprogress.done()
              this.creditNotePrefixData = response.data.message;
            })
            .catch(error => {
              if (error.response) {
                if (error.response.status == 403) {
                  window.location = "/dashboard";
                }
              }
            })
        },
        fetchCafPrefixData() {
          Nprogress.start()
          axios.get("/settings/caf_prefix/true")
            .then(response => {
              Nprogress.done()
              this.cafPrefixData = response.data.message;
            })
            .catch(error => {
              if (error.response) {
                if (error.response.status == 403) {
                  window.location = "/dashboard";
                }
              }
            })
        },
        deleteNumberConfirm(postData) {
          return new Promise((resolve, reject) => {
            JsLoadingOverlay.show({
                    'spinnerIcon': 'ball-clip-rotate',
                    "overlayOpacity": "0.6",
                    "spinnerSize": "2x",
                    "lockScroll": true,
                });
            if(postData.funcName == "deleteNumforInvoice") {
              axios.post('/settings/delete_invoice_number_prefix', qs.stringify(postData, {
                parseArrays: false
              }))
                .then(response => {
                  JsLoadingOverlay.hide();;
                  if (response.data.status != 'success') {
                    reject(response.data.message);
                    return;
                  }
                  var successData = {
                    invoiceNumber: response.data.invoiceNumber,
                    businessInvoiceNumber: response.data.businessInvoiceNumber
                  }
                  this.updateInvoiceNumber(successData)
                  resolve(response.data.message);
                })
                .catch(error => console.log(error));
            } else if(postData.funcName == "deleteNumforProformaInvoice") {
              axios.post('/settings/delete_Proforma_number_prefix', qs.stringify(postData, {
                parseArrays: false
              }))
                .then(response => {
                  JsLoadingOverlay.hide();;
                  if (response.data.status != 'success') {
                    reject(response.data.message);
                    return;
                  }
                  var successData = {
                    proformaInvoiceNumber: response.data.proformaNumber,
                    businessProformaInvoiceNumber: response.data.businessProformaInvoiceNumber
                  }
                  this.updateProformaInvoiceNumber(successData)
                  resolve(response.data.message);
                })
                .catch(error => console.log(error));
            } else if(postData.funcName == "deleteNumforPayment") {
              axios.post('/settings/delete_payment_number_prefix', qs.stringify(postData, {
                parseArrays: false
              }))
                .then(response => {
                  JsLoadingOverlay.hide();;
                  if (response.data.status != 'success') {
                    reject(response.data.message);
                    return;
                  }
                  var successData = {
                    paymentNumber: response.data.paymentNumber,
                    businessPaymentNumber: response.data.businessPaymentNumber
                  }
                  this.updatePaymentNumber(successData)
                  resolve(response.data.message);
                })
                .catch(error => console.log(error));
            } else if(postData.funcName == "deleteNumforCircuit") {
              axios.post('/settings/delete_circuit_number_prefix', qs.stringify(postData, {
                parseArrays: false
              }))
                .then(response => {
                  JsLoadingOverlay.hide();;
                  if (response.data.status != 'success') {
                    reject(response.data.message);
                    return;
                  }
                  var successData = response.data.circuitNumber;
                  this.updateCircuitIdNumber(successData)
                  resolve(response.data.message);
                })
                .catch(error => console.log(error));
            } else if(postData.funcName == "deleteNumforLead") {
              axios.post('/settings/delete_lead_number_prefix', qs.stringify(postData, {
                parseArrays: false
              }))
                .then(response => {
                  JsLoadingOverlay.hide();;
                  if (response.data.status != 'success') {
                    reject(response.data.message);
                    return;
                  }
                  var successData = response.data.leadNumber;
                  this.updateLeadNumber(successData)
                  resolve(response.data.message);
                })
                .catch(error => console.log(error));
            } else if(postData.funcName == "deleteNumforHelpdesk") {
              axios.post('/settings/delete_helpdesk_number_prefix', qs.stringify(postData, {
                parseArrays: false
              }))
                .then(response => {
                  JsLoadingOverlay.hide();;
                  if (response.data.status != 'success') {
                    reject(response.data.message);
                    return;
                  }
                  var successData = response.data.helpdeskNumber;
                  this.updateHelpdeskNumber(successData)
                  resolve(response.data.message);
                })
                .catch(error => console.log(error));
            } else if(postData.funcName == "deleteNumforCreditNote") {
              axios.post('/settings/delete_credit_note_number_prefix', qs.stringify(postData, {
                parseArrays: false
              }))
                .then(response => {
                  JsLoadingOverlay.hide();;
                  if (response.data.status != 'success') {
                    reject(response.data.message);
                    return;
                  }                  
                  var successData = {
                    creditNoteNumber: response.data.paymentNumber,
                    businessCreditNoteNumber: response.data.businessCreditNoteNumber
                  }
                  this.updateCreditNoteNumber(successData)
                  resolve(response.data.message);
                })
                .catch(error => console.log(error));
            } else if(postData.funcName == "deleteNumforCaf") {
              axios.post('/settings/delete_caf_number_prefix', qs.stringify(postData, {
                parseArrays: false
              }))
                .then(response => {
                  JsLoadingOverlay.hide();;
                  if (response.data.status != 'success') {
                    reject(response.data.message);
                    return;
                  }
                  var successData = response.data.cafNumber;
                  this.updateCafNumber(successData)
                  resolve(response.data.message);
                })
                .catch(error => console.log(error));
            }
          })
        }, 
        updateInvoiceNumber(data) {
          this.invoicePrefixData['selectedBusinessInvoiceNumber'] = data.businessInvoiceNumber;
          this.invoicePrefixData['selectedInvoiceNumber'] = data.invoiceNumber;
        },
        updateProformaInvoiceNumber(data) {
          this.proformaInvoicePrefixData['selectedBusinessProformaInvoiceNumber'] = data.businessProformaInvoiceNumber;
          this.proformaInvoicePrefixData['selectedProformaNumber'] = data.proformaInvoiceNumber;
        },
        updatePaymentNumber(data) {
          this.paymentPrefixData['selectedBusinessPaymentNumber'] = data.businessPaymentNumber;
          this.paymentPrefixData['selectedPaymentNumber'] = data.paymentNumber;
        },
        updateCreditNoteNumber(data) {
          this.creditNotePrefixData['selectedBusinessCreditNoteNumber'] = data.businessCreditNoteNumber;
          this.creditNotePrefixData['selectedPaymentNumber'] = data.creditNoteNumber;
        },
        updateCircuitIdNumber(data) {
          this.circuitIdPrefixData['selectedCircuitNumber'] = data;
        },
        updateLeadNumber(data) {
          this.leadPrefixData['selectedLeadNumber'] = data;
        },
        updateHelpdeskNumber(data) {
          this.helpdeskPrefixData['selectedHelpDeskNumber'] = data;
        },
        updateCafNumber(data) {
          this.cafPrefixData['selectedCafNumber'] = data;
        },
        createPrefixConfirm(postData) {
          return new Promise((resolve, reject) => {
            JsLoadingOverlay.show({
                    'spinnerIcon': 'ball-clip-rotate',
                    "overlayOpacity": "0.6",
                    "spinnerSize": "2x",
                    "lockScroll": true,
                });
            if(postData.funcName == "createPrefixforInvoice") {
              axios.post('/settings/invoice_prefix_submit', qs.stringify(postData, {
                parseArrays: false
              }))
                .then(response => {
                  JsLoadingOverlay.hide();;
                  if (response.data.status != 'success') {
                    reject(response.data.message);
                    return;
                  }
                  var successData = response.data.prefixList;
                  this.updateInvoicePrefix(successData)
                  resolve(response.data.message);
                })
                .catch(error => console.log(error));
              
            } else if(postData.funcName == "createPrefixforProformaInvoice") {
                axios.post('/settings/proforma_invoice_prefix_submit', qs.stringify(postData, {
                  parseArrays: false
                }))
                  .then(response => {
                    JsLoadingOverlay.hide();;
                    if (response.data.status != 'success') {
                      reject(response.data.message);
                      return;
                    }
                    var successData = response.data.prefixList;
                    this.updateProformaInvoicePrefix(successData)
                    resolve(response.data.message);
                  })
                  .catch(error => console.log(error));
            } else if(postData.funcName == "createPrefixforPayment") {
              axios.post('/settings/payment_prefix_submit', qs.stringify(postData, {
                parseArrays: false
              }))
                .then(response => {
                  JsLoadingOverlay.hide();;
                  if (response.data.status != 'success') {
                    reject(response.data.message);
                    return;
                  }
                  var successData = response.data.prefixList;
                  this.updatePaymentPrefix(successData)
                  resolve(response.data.message);    
                })
                .catch(error => console.log(error));
            } else if(postData.funcName == "createPrefixforCircuit") {
                axios.post('/settings/curcuit_id_prefix_submit', qs.stringify(postData, {
                  parseArrays: false
                }))
                  .then(response => {
                    JsLoadingOverlay.hide();;
                    if (response.data.status != 'success') {
                      reject(response.data.message);
                      return;
                    }
                    var successData = response.data.prefixList;
                    this.updateCircuitIdPrefix(successData)
                    resolve(response.data.message);
                  })
                  .catch(error => console.log(error));
            } else if(postData.funcName == "createPrefixforLead") {
                axios.post('/settings/lead_prefix_submit', qs.stringify(postData, {
                  parseArrays: false
                }))
                  .then(response => {
                    JsLoadingOverlay.hide();;
                    if (response.data.status != 'success') {
                      reject(response.data.message);
                      return;
                    }
                    var successData = response.data.prefixList;
                    this.updateLeadPrefix(successData)
                    resolve(response.data.message);
                  })
                  .catch(error => console.log(error));
            } else if(postData.funcName == "createPrefixforHelpdesk") {
                axios.post('/settings/helpdesk_prefix_submit', qs.stringify(postData, {
                  parseArrays: false
                }))
                  .then(response => {
                    JsLoadingOverlay.hide();;
                    if (response.data.status != 'success') {
                      reject(response.data.message);
                      return;
                    }
                    var successData = response.data.prefixList;
                    this.updateHelpdeskPrefix(successData)
                    resolve(response.data.message);
                  })
                  .catch(error => console.log(error));
            } else if(postData.funcName == "createPrefixforCreditNote") {
                axios.post('/settings/credit_note_prefix_submit', qs.stringify(postData, {
                  parseArrays: false
                }))
                  .then(response => {
                    JsLoadingOverlay.hide();;
                    if (response.data.status != 'success') {
                      reject(response.data.message);
                      return;
                    }
                    var successData = response.data.prefixList;
                    this.updateCreditNotePrefix(successData)
                    resolve(response.data.message);
                  })
                  .catch(error => console.log(error));
            } else if(postData.funcName == "createPrefixforCaf") {
                axios.post('/settings/caf_prefix_submit', qs.stringify(postData, {
                  parseArrays: false
                }))
                  .then(response => {
                    JsLoadingOverlay.hide();;
                    if (response.data.status != 'success') {
                      reject(response.data.message);
                      return;
                    }
                    var successData = response.data.prefixList;
                    this.updateCafPrefix(successData)
                    resolve(response.data.message);
                  })
                  .catch(error => console.log(error));
            }
          });
        },
        updateInvoicePrefix(data) {
          this.invoicePrefixData['prefixIn'] = data;
        },
        updateProformaInvoicePrefix(data) {
          this.proformaInvoicePrefixData['prefixIn'] = data;
        },
        updatePaymentPrefix(data) {
          this.paymentPrefixData['prefixIn'] = data;
        },
        updateCreditNotePrefix(data) {
          this.creditNotePrefixData['prefixIn'] = data;
        },
        updateCafPrefix(data) {
          this.cafPrefixData['prefixIn'] = data;
        },
        updateCircuitIdPrefix(data) {
          this.circuitIdPrefixData['prefixIn'] = data;
        },
        updateLeadPrefix(data) {
          this.leadPrefixData['prefixIn'] = data;
        },
        updateHelpdeskPrefix(data) {
          this.helpdeskPrefixData['prefixIn'] = data;
        },
        deletePrefixConfirm(postData) {
          return new Promise((resolve, reject) => {
            JsLoadingOverlay.show({
                    'spinnerIcon': 'ball-clip-rotate',
                    "overlayOpacity": "0.6",
                    "spinnerSize": "2x",
                    "lockScroll": true,
                });
            if(postData.funcName == "deletePrefixforInvoice") {
              axios.post('/settings/delete_invoice_prefix', qs.stringify(postData, {
                parseArrays: false
              }))
                .then(response => {
                  JsLoadingOverlay.hide();;
                  if (response.data.status != 'success') {
                    reject(response.data.message);
                    return;
                  }
                  var successData = response.data.prefixList;
                  this.updateInvoicePrefix(successData)
                  resolve(response.data.message);
                })
                .catch(error => console.log(error));
            } else if(postData.funcName == "deletePrefixforProformaInvoice") {
              axios.post('/settings/delete_proforma_invoice_prefix', qs.stringify(postData, {
                parseArrays: false
              }))
                .then(response => {
                  JsLoadingOverlay.hide();;
                  if (response.data.status != 'success') {
                    reject(response.data.message);
                    return;
                  }
                  var successData = response.data.prefixList;
                  this.updateProformaInvoicePrefix(successData)
                  resolve(response.data.message);
                })
                .catch(error => console.log(error));
            } else if(postData.funcName == "deletePrefixforPayment") {
              axios.post('/settings/delete_payment_prefix', qs.stringify(postData, {
                parseArrays: false
              }))
                .then(response => {
                  JsLoadingOverlay.hide();;
                  if (response.data.status != 'success') {
                    reject(response.data.message);
                    return;
                  }
                  var successData = response.data.prefixList;
                  this.updatePaymentPrefix(successData)
                  resolve(response.data.message);
                })
                .catch(error => console.log(error));
            } else if(postData.funcName == "deletePrefixforCircuit") {
              axios.post('/settings/delete_circuit_id_prefix', qs.stringify(postData, {
                parseArrays: false
              }))
                .then(response => {
                  JsLoadingOverlay.hide();;
                  if (response.data.status != 'success') {
                    reject(response.data.message);
                    return;
                  }
                  var successData = response.data.prefixList;
                  this.updateCircuitIdPrefix(successData)
                  resolve(response.data.message);
                })
                .catch(error => console.log(error));
            } else if(postData.funcName == "deletePrefixforLead") {
              axios.post('/settings/delete_lead_prefix', qs.stringify(postData, {
                parseArrays: false
              }))
                .then(response => {
                  JsLoadingOverlay.hide();;
                  if (response.data.status != 'success') {
                    reject(response.data.message);
                    return;
                  }
                  var successData = response.data.prefixList;
                  this.updateLeadPrefix(successData)
                  resolve(response.data.message);
                })
                .catch(error => console.log(error));
            } else if(postData.funcName == "deletePrefixforHelpdesk") {
              axios.post('/settings/delete_helpdesk_prefix', qs.stringify(postData, {
                parseArrays: false
              }))
                .then(response => {
                  JsLoadingOverlay.hide();;
                  if (response.data.status != 'success') {
                    reject(response.data.message);
                    return;
                  }
                  var successData = response.data.prefixList;
                  this.updateHelpdeskPrefix(successData)
                  resolve(response.data.message);
                })
                .catch(error => console.log(error));
            } else if(postData.funcName == "deletePrefixforCreditNote") {
              axios.post('/settings/delete_credit_note_prefix', qs.stringify(postData, {
                parseArrays: false
              }))
                .then(response => {
                  JsLoadingOverlay.hide();;
                  if (response.data.status != 'success') {
                    reject(response.data.message);
                    return;
                  }
                  var successData = response.data.prefixList;
                  this.updateCreditNotePrefix(successData)
                  resolve(response.data.message);
                })
                .catch(error => console.log(error));
            } else if(postData.funcName == "deletePrefixforCaf") {
              axios.post('/settings/delete_caf_prefix', qs.stringify(postData, {
                parseArrays: false
              }))
                .then(response => {
                  JsLoadingOverlay.hide();;
                  if (response.data.status != 'success') {
                    reject(response.data.message);
                    return;
                  }
                  var successData = response.data.prefixList;
                  this.updateCafPrefix(successData)
                  resolve(response.data.message);
                })
                .catch(error => console.log(error));
            }
          })
        },
      
        addEditInvoiceNum(postData) {
          return new Promise((resolve, reject) => {
            JsLoadingOverlay.show({
                    'spinnerIcon': 'ball-clip-rotate',
                    "overlayOpacity": "0.6",
                    "spinnerSize": "2x",
                    "lockScroll": true,
                });
            if(postData.funcName == "createInvoiceNumber") {
              axios.post('/settings/invoice_prefix_number_submit', qs.stringify(postData, {
                parseArrays: false
              }))
                .then(response => {
                  JsLoadingOverlay.hide();;
                  if (response.data.status != 'success') {
                    reject(response.data.message);
                    return;
                  }
                  var successData = {
                    invoiceNumber: response.data.invoiceNumber,
                    businessInvoiceNumber: response.data.businessInvoiceNumber
                  }
                  this.updateInvoiceNumber(successData)
                  resolve(response.data.message);
                })
                .catch(error => console.log(error));
            } else if(postData.funcName == "createProformaInvoiceNumber") {
              axios.post('/settings/proforma_prefix_number_submit', qs.stringify(postData, {
                parseArrays: false
              }))
                .then(response => {
                  JsLoadingOverlay.hide();;
                  if (response.data.status != 'success') {
                    reject(response.data.message);
                    return;
                  }
                  var successData = {
                    proformaInvoiceNumber: response.data.invoiceNumber,
                    businessProformaInvoiceNumber: response.data.businessProformaInvoiceNumber
                  }
                  this.updateProformaInvoiceNumber(successData)
                  resolve(response.data.message);
                })
                .catch(error => console.log(error));
            } else if(postData.funcName == "createPaymentNumber") {
              axios.post('/settings/payment_prefix_number_submit', qs.stringify(postData, {
                parseArrays: false
              }))
                .then(response => {
                  JsLoadingOverlay.hide();;
                  if (response.data.status != 'success') {
                    reject(response.data.message);
                    return;
                  }
                  var successData = {
                    paymentNumber: response.data.invoiceNumber,
                    businessPaymentNumber: response.data.businessPaymentNumber
                  }
                  this.updatePaymentNumber(successData)
                  resolve(response.data.message);
                })
                .catch(error => console.log(error));
            } else if(postData.funcName == "createCircuitIdNumber") {
              axios.post('/settings/circuit_prefix_number_submit', qs.stringify(postData, {
                parseArrays: false
              }))
                .then(response => {
                  JsLoadingOverlay.hide();;
                  if (response.data.status != 'success') {
                    reject(response.data.message);
                    return;
                  }
                  var successData = response.data.circuitNumber;
                  this.updateCircuitIdNumber(successData)
                  resolve(response.data.message);
                })
                .catch(error => console.log(error));
            } else if(postData.funcName == "createLeadNumber") {
              axios.post('/settings/lead_prefix_number_submit', qs.stringify(postData, {
                parseArrays: false
              }))
                .then(response => {
                  JsLoadingOverlay.hide();;
                  if (response.data.status != 'success') {
                    reject(response.data.message);
                    return;
                  }
                  var successData = response.data.leadNumber;
                  this.updateLeadNumber(successData)
                  resolve(response.data.message);
                })
                .catch(error => console.log(error));
            } else if(postData.funcName == "createHelpdeskNumber") {
              axios.post('/settings/helpdesk_prefix_number_submit', qs.stringify(postData, {
                parseArrays: false
              }))
                .then(response => {
                  JsLoadingOverlay.hide();;
                  if (response.data.status != 'success') {
                    reject(response.data.message);
                    return;
                  }
                  var successData = response.data.helpdeskNumber;
                  this.updateHelpdeskNumber(successData)
                  resolve(response.data.message);
                })
                .catch(error => console.log(error));
            } else if(postData.funcName == "createCreditNoteNumber") {
              axios.post('/settings/credit_note_prefix_number_submit', qs.stringify(postData, {
                parseArrays: false
              }))
                .then(response => {
                  JsLoadingOverlay.hide();;
                  if (response.data.status != 'success') {
                    reject(response.data.message);
                    return;
                  }
                  var successData = {
                    creditNoteNumber: response.data.invoiceNumber,
                    businessCreditNoteNumber: response.data.businessCreditNoteNumber
                  }
                  this.updateCreditNoteNumber(successData)
                  resolve(response.data.message);
                })
                .catch(error => console.log(error));
            } else if(postData.funcName == "createCafNumber") {
              axios.post('/settings/caf_prefix_number_submit', qs.stringify(postData, {
                parseArrays: false
              }))
                .then(response => {
                  JsLoadingOverlay.hide();;
                  if (response.data.status != 'success') {
                    reject(response.data.message);
                    return;
                  }
                  var successData = response.data.cafNumber;
                  this.updateCafNumber(successData)
                  resolve(response.data.message);
                })
                .catch(error => console.log(error));
            }
          })
        },
        savePrefixSettings(postData) {
          return new Promise((resolve, reject) => {
            JsLoadingOverlay.show({
                    'spinnerIcon': 'ball-clip-rotate',
                    "overlayOpacity": "0.6",
                    "spinnerSize": "2x",
                    "lockScroll": true,
                });
            if(postData.funcName == "invoicePrefixSettingsSubmit") {
              axios.post('/settings/submit_invoice_prefix_form', qs.stringify(postData, {
                parseArrays: false
              }))
                .then(response => {
                  JsLoadingOverlay.hide();;
                  if (response.data.status != 'success') {
                    reject(response.data.message);
                    return;
                  }
                  resolve(response.data.message);
                })
                .catch(error => console.log(error));
            } else if(postData.funcName == "paymentPrefixSettingsSubmit") {
              axios.post('/settings/submit_payment_prefix_form', qs.stringify(postData, {
                parseArrays: false
              }))
                .then(response => {
                  JsLoadingOverlay.hide();;
                  if (response.data.status != 'success') {
                    reject(response.data.message);
                    return;
                  }
                  resolve(response.data.message);
                })
                .catch(error => console.log(error));
            } else if(postData.funcName == "circuitPrefixSettingsSubmit") {
              axios.post('/settings/submit_circuit_id_prefix_form', qs.stringify(postData, {
                parseArrays: false
              }))
                .then(response => {
                  JsLoadingOverlay.hide();;
                  if (response.data.status != 'success') {
                    reject(response.data.message);
                    return;
                  }
                  resolve(response.data.message);
                })
                .catch(error => console.log(error));
            } else if(postData.funcName == "leadPrefixSettingsSubmit") {
              axios.post('/settings/submit_lead_prefix_form', qs.stringify(postData, {
                parseArrays: false
              }))
                .then(response => {
                  JsLoadingOverlay.hide();;
                  if (response.data.status != 'success') {
                    reject(response.data.message);
                    return;
                  }
                  resolve(response.data.message);
                })
                .catch(error => console.log(error));
            } else if(postData.funcName == "helpdeskPrefixSettingsSubmit") {
              axios.post('/settings/submit_helpdesk_prefix_form', qs.stringify(postData, {
                parseArrays: false
              }))
                .then(response => {
                  JsLoadingOverlay.hide();;
                  if (response.data.status != 'success') {
                    reject(response.data.message);
                    return;
                  }
                  resolve(response.data.message);
                })
                .catch(error => console.log(error));
            } else if(postData.funcName == "creditNotePrefixSettingsSubmit") {
              axios.post('/settings/submit_credit_note_prefix_form', qs.stringify(postData, {
                parseArrays: false
              }))
                .then(response => {
                  JsLoadingOverlay.hide();;
                  if (response.data.status != 'success') {
                    reject(response.data.message);
                    return;
                  }
                  resolve(response.data.message);
                })
                .catch(error => console.log(error));
            } else if(postData.funcName == "cafPrefixSettingsSubmit") {
              axios.post('/settings/submit_caf_prefix_form', qs.stringify(postData, {
                parseArrays: false
              }))
                .then(response => {
                  JsLoadingOverlay.hide();;
                  if (response.data.status != 'success') {
                    reject(response.data.message);
                    return;
                  }
                  resolve(response.data.message);
                })
                .catch(error => console.log(error));
            }
          })
        },
      
        usernamePrefixSUbmit(postData) {
          return new Promise((resolve, reject) => {
            JsLoadingOverlay.show({
                    'spinnerIcon': 'ball-clip-rotate',
                    "overlayOpacity": "0.6",
                    "spinnerSize": "2x",
                    "lockScroll": true,
                });
            axios.post('/settings/username_prefix_submit', qs.stringify(postData, {
              parseArrays: false
            }))
              .then(response => {
                JsLoadingOverlay.hide();;
                if (response.data.status != 'success') {
                  reject(response.data.message);
                  return;
                }
                resolve(response.data.message);
              })
              .catch(error => console.log(error));
          });
        },
        updateAutoCaf(postData) {
          return new Promise((resolve, reject) => {
            JsLoadingOverlay.show({
                    'spinnerIcon': 'ball-clip-rotate',
                    "overlayOpacity": "0.6",
                    "spinnerSize": "2x",
                    "lockScroll": true,
                });
            axios.post('/settings/caf_auto_generate', qs.stringify(postData, {
              parseArrays: false
            }))
              .then(response => {
                JsLoadingOverlay.hide();;
                if (response.data.status != 'success') {
                  reject(response.data.message);
                  return;
                }
                var successData = response.data.automaticCafGeneration;
                this.cafPrefixData['automaticCafGeneration'] = successData;
                resolve(response.data.message);
              })
              .catch(error => console.log(error));
          });
        },
        addPrefixConfigToAllSubZones(postData) {
          return new Promise((resolve, reject) => {
            JsLoadingOverlay.show({
                    'spinnerIcon': 'ball-clip-rotate',
                    "overlayOpacity": "0.6",
                    "spinnerSize": "2x",
                    "lockScroll": true,
                });
            axios.post('/settings/add_prefix_toall_subaccounts', qs.stringify(postData, {
              parseArrays: false
            }))
              .then(response => {
                JsLoadingOverlay.hide();
                if (response.data.status != 'success') {
                  reject(response.data.message);
                  return;
                }
                resolve(response.data.genericBody);
              })
              .catch(error => console.log(error));
          });
        },
        saveSubAccountPermissionSettings(postData) {
          return new Promise((resolve, reject) => {
            JsLoadingOverlay.show({
                    'spinnerIcon': 'ball-clip-rotate',
                    "overlayOpacity": "0.6",
                    "spinnerSize": "2x",
                    "lockScroll": true,
                });
            axios.post('/settings/save_sub_accounts_cannot_change_settings', qs.stringify(postData, {
              parseArrays: false
            }))
              .then(response => {
                JsLoadingOverlay.hide();
                if (response.data.status != 'success') {
                  reject(response.data.message);
                  return;
                }
                this.setCheckboxValue(postData);
                resolve(response.data.genericBody);
              })
              .catch(error => console.log(error));
          });
        },
        fetchTableHeaderConfig() {
          Nprogress.start()
          axios.get("/settings/table_header_configuration/true")
            .then(response => {
              Nprogress.done()
              this.tableHeaderConfigData = response.data.message;
            })
            .catch(error => {
              if (error.response) {
                if (error.response.status == 403) {
                  window.location = "/dashboard";
                }
              }
            })

        },
        fetchReportColumnVisibleConfig() {
          Nprogress.start()
          axios.get("/settings/report_column_visible_configuration/true")
            .then(response => {
              Nprogress.done()
              this.reportVisibleColumnData = response.data.message;
            })
            .catch(error => {
              if (error.response) {
                if (error.response.status == 403) {
                  window.location = "/dashboard";
                }
              }
            })
        },
        addTableConfigToAllSubZones(postData) {
          return new Promise((resolve, reject) => {
            JsLoadingOverlay.show({
                    'spinnerIcon': 'ball-clip-rotate',
                    "overlayOpacity": "0.6",
                    "spinnerSize": "2x",
                    "lockScroll": true,
                });
            axios.post('/settings/add_tables_toall_subaccounts', qs.stringify(postData, {
              parseArrays: false
            }))
              .then(response => {
                JsLoadingOverlay.hide();
                if (response.data.status != 'success') {
                  reject(response.data.message);
                  return;
                }
                resolve(response.data.genericBody);
              })
              .catch(error => console.log(error));
          });
        },
        addReportColumnToAllSubZones(postData) {
          return new Promise((resolve, reject) => {
            JsLoadingOverlay.show({
                    'spinnerIcon': 'ball-clip-rotate',
                    "overlayOpacity": "0.6",
                    "spinnerSize": "2x",
                    "lockScroll": true,
                });
            axios.post('/settings/add_report_column_visible_toall_subaccounts', qs.stringify(postData, {
              parseArrays: false
            }))
              .then(response => {
                JsLoadingOverlay.hide();
                if (response.data.status != 'success') {
                  reject(response.data.message);
                  return;
                }
                resolve(response.data.genericBody);
              })
              .catch(error => console.log(error));
          });
        },
        tableConfigSetDefaults(postData) {
          return new Promise((resolve, reject) => {
            JsLoadingOverlay.show({
                    'spinnerIcon': 'ball-clip-rotate',
                    "overlayOpacity": "0.6",
                    "spinnerSize": "2x",
                    "lockScroll": true,
                });
            axios.post('/settings/reset_table_headers', qs.stringify(postData, {
              parseArrays: false
            }))
              .then(response => {
                JsLoadingOverlay.hide();
                if (response.data.status != 'success') {
                  reject(response.data.message);
                  return;
                }
                resolve(response.data.message);
              })
              .catch(error => console.log(error));
          });
        },
        reportColumnVisibleSetDefaults(postData) {
          return new Promise((resolve, reject) => {
            JsLoadingOverlay.show({
                    'spinnerIcon': 'ball-clip-rotate',
                    "overlayOpacity": "0.6",
                    "spinnerSize": "2x",
                    "lockScroll": true,
                });
            axios.post('/settings/reset_report_column_visible', qs.stringify(postData, {
              parseArrays: false
            }))
              .then(response => {
                JsLoadingOverlay.hide();
                if (response.data.status != 'success') {
                  reject(response.data.message);
                  return;
                }
                this.reportVisibleColumnData.UserDetailsReportVisibleColumn=response.data.UserDetailsReportVisibleColumn;
               
                resolve(response.data.message);
              })
              .catch(error => console.log(error));
          });
        },
        tableConfigSetDefaultsToSubZones(postData) {
          return new Promise((resolve, reject) => {
            JsLoadingOverlay.show({
                    'spinnerIcon': 'ball-clip-rotate',
                    "overlayOpacity": "0.6",
                    "spinnerSize": "2x",
                    "lockScroll": true,
                });
            axios.post('/settings/add_default_toall_subaccounts', qs.stringify(postData, {
              parseArrays: false
            }))
              .then(response => {
                JsLoadingOverlay.hide();
                if (response.data.status != 'success') {
                  reject(response.data.message);
                  return;
                }
                resolve(response.data.message);
              })
              .catch(error => console.log(error));
          });
        },
        reportColumnVisibleSetDefaultsToSubZones(postData) {
          return new Promise((resolve, reject) => {
            JsLoadingOverlay.show({
                    'spinnerIcon': 'ball-clip-rotate',
                    "overlayOpacity": "0.6",
                    "spinnerSize": "2x",
                    "lockScroll": true,
                });
            axios.post('/settings/add_Report_Column_visible_default_toall_subaccounts', qs.stringify(postData, {
              parseArrays: false
            }))
              .then(response => {
                JsLoadingOverlay.hide();
                if (response.data.status != 'success') {
                  reject(response.data.message);
                  return;
                }
                resolve(response.data.message);
              })
              .catch(error => console.log(error));
          });
        },
        tableHeaderConfigsubmit(postData) {
          return new Promise((resolve, reject) => {
            JsLoadingOverlay.show({
                    'spinnerIcon': 'ball-clip-rotate',
                    "overlayOpacity": "0.6",
                    "spinnerSize": "2x",
                    "lockScroll": true,
                });
            axios.post('/settings/submit_table_header_configuration_form', qs.stringify(postData, {
              parseArrays: false
            }))
              .then(response => {
                JsLoadingOverlay.hide();;
                if (response.data.status != 'success') {
                  reject(response.data.message);
                  return;
                }
                resolve(response.data.message);
              })
              .catch(error => console.log(error));
          });
        },
        reportColumnVisibleConfigsubmit(postData) {
          return new Promise((resolve, reject) => {
            JsLoadingOverlay.show({
                    'spinnerIcon': 'ball-clip-rotate',
                    "overlayOpacity": "0.6",
                    "spinnerSize": "2x",
                    "lockScroll": true,
                });
            axios.post('/settings/submit_report_column_visble_configuration_form', qs.stringify(postData, {
              parseArrays: false
            }))
              .then(response => {
                JsLoadingOverlay.hide();;
                if (response.data.status != 'success') {
                  reject(response.data.message);
                  return;
                }
                resolve(response.data.message);
              })
              .catch(error => console.log(error));
          });
        },
        fetchAdminUserGroups() {
          Nprogress.start()
          axios.get("/settings/admin_settings/true")
            .then(response => {
              Nprogress.done()
              this.adminSettingsData = response.data.message;
            })
            .catch(error => {
              if (error.response) {
                if (error.response.status == 403) {
                  window.location = "/dashboard";
                }
              }
            })
        },
        adminSettingsSubmit(postData) {
          return new Promise((resolve, reject) => {
            JsLoadingOverlay.show({
                    'spinnerIcon': 'ball-clip-rotate',
                    "overlayOpacity": "0.6",
                    "spinnerSize": "2x",
                    "lockScroll": true,
                });
            axios.post('/settings/admin_settings_submit', qs.stringify(postData, {
              parseArrays: false
            }))
              .then(response => {
                JsLoadingOverlay.hide();;
                if (response.data.status != 'success') {
                  reject(response.data.message);
                  return;
                }
                resolve(response.data.message);
              })
              .catch(error => console.log(error));
          });
        },
        fetchapprovalTasks() {
          Nprogress.start()
          axios.get("/settings/task_settings/true")
            .then(response => {
              Nprogress.done()
              this.approvalTaskData = response.data.message;
            })
            .catch(error => {
              if (error.response) {
                if (error.response.status == 403) {
                  window.location = "/dashboard";
                }
              }
            })
        },
        taskSettingsSubmit(postData) {
          return new Promise((resolve, reject) => {
            JsLoadingOverlay.show({
                    'spinnerIcon': 'ball-clip-rotate',
                    "overlayOpacity": "0.6",
                    "spinnerSize": "2x",
                    "lockScroll": true,
                });
            axios.post('/settings/task_settings_submit', qs.stringify(postData, {
              parseArrays: false
            }))
              .then(response => {
                JsLoadingOverlay.hide();;
                if (response.data.status != 'success') {
                  reject(response.data.message);
                  return;
                }
                resolve(response.data.message);
              })
              .catch(error => console.log(error));
          });
        },
        fetchProfileSettings() {
          Nprogress.start()
          axios.get("/settings/profile_settings/true")
            .then(response => {
              Nprogress.done()
              this.profileSettingsData = response.data.message;
            })
            .catch(error => {
              if (error.response) {
                if (error.response.status == 403) {
                  window.location = "/dashboard";
                }
              }
            })
        },
        profileSettingsSubmit(postData) {
          return new Promise((resolve, reject) => {
            JsLoadingOverlay.show({
                    'spinnerIcon': 'ball-clip-rotate',
                    "overlayOpacity": "0.6",
                    "spinnerSize": "2x",
                    "lockScroll": true,
                });
            axios.post('/settings/profile_settings_submit', qs.stringify(postData, {
              parseArrays: false
            }))
              .then(response => {
                JsLoadingOverlay.hide();;
                if (response.data.status != 'success') {
                  var res = {};
                  if (response.data.status == 'profile name error') {
                    res.profileGroups = response.data.message;
                    res.type = 'profileNameError'
                  } else {
                    res.profileGroups = response.data.message;
                    res.type = 'error'
                  }
                  reject(res);
                  return;
                }
                resolve(response.data.message);
              })
              .catch(error => console.log(error));
          });
        },
        addRemovePackage(postData) {
          return new Promise((resolve, reject) => {
            JsLoadingOverlay.show({
                    'spinnerIcon': 'ball-clip-rotate',
                    "overlayOpacity": "0.6",
                    "spinnerSize": "2x",
                    "lockScroll": true,
                });
            if(postData.funcName == 'addPackageSubmit') {
              axios.post('/settings/save_package_deactivate', qs.stringify(postData, {
                parseArrays: false
              }))
                .then(response => {
                  JsLoadingOverlay.hide();;
                  if (response.data.status != 'success') {
                    reject(response.data.message);
                    return;
                  }
                  this.updateProfileSettingsData(response.data.accountsArr);
                  resolve(response.data.message);
                })
                .catch(error => console.log(error));
            } else if(postData.funcName == 'removePackageSubmit') {
              axios.post('/settings/delete_package_deactivate', qs.stringify(postData, {
                parseArrays: false
              }))
                .then(response => {
                  JsLoadingOverlay.hide();;
                  if (response.data.status != 'success') {
                    reject(response.data.message);
                    return;
                  }
                  this.updateProfileSettingsData(response.data.accountsArr);
                  resolve(response.data.message);
                })
                .catch(error => console.log(error));
            }
          });
        },
        updateProfileSettingsData(data) {
          this.profileSettingsData['accountsArr'] = data;
        },
        fetchPayAsYouGoDetails() {
          Nprogress.start()
          axios.get("/settings/payasyougo_settings/true")
            .then(response => {
              Nprogress.done()
              this.payAsYouGoData = response.data.message;
            })
            .catch(error => {
              if (error.response) {
                if (error.response.status == 403) {
                  window.location = "/dashboard";
                }
              }
            })
        },
        payAsYouGoSettingsSubmit(postData) {
          return new Promise((resolve, reject) => {
            JsLoadingOverlay.show({
                    'spinnerIcon': 'ball-clip-rotate',
                    "overlayOpacity": "0.6",
                    "spinnerSize": "2x",
                    "lockScroll": true,
                });
            axios.post('/settings/payasyougo_settings_submit', qs.stringify(postData, {
              parseArrays: false
            }))
              .then(response => {
                JsLoadingOverlay.hide();;
                if (response.data.status != 'success') {
                  reject(response.data.message);
                  return;
                }
                resolve(response.data.message);
              })
              .catch(error => console.log(error));
          });
        },
        addPayAsYouGoToAllSubZones(postData) {
          return new Promise((resolve, reject) => {
            JsLoadingOverlay.show({
                    'spinnerIcon': 'ball-clip-rotate',
                    "overlayOpacity": "0.6",
                    "spinnerSize": "2x",
                    "lockScroll": true,
                });
            axios.post('/settings/add_allto_payasyougo_subaccounts', qs.stringify(postData, {
              parseArrays: false
            }))
              .then(response => {
                JsLoadingOverlay.hide();
                if (response.data.status != 'success') {
                  reject(response.data.message);
                  return;
                }
                resolve(response.data.genericBody);
              })
              .catch(error => console.log(error));
          });
        },
        fetchLeadSettingDetails() {
          Nprogress.start()
          axios.get("/settings/lead_settings/true")
            .then(response => {
              Nprogress.done()              
              this.leadSettingsData = response.data.message;
            })
            .catch(error => {
              if (error.response) {
                if (error.response.status == 403) {
                  window.location = "/dashboard";
                }
              }
            })
        },
        leadSettingsSubmit(postData) {
          return new Promise((resolve, reject) => {
            JsLoadingOverlay.show({
                    'spinnerIcon': 'ball-clip-rotate',
                    "overlayOpacity": "0.6",
                    "spinnerSize": "2x",
                    "lockScroll": true,
                });
            axios.post('/settings/lead_settings_submit', qs.stringify(postData, {
              parseArrays: false
            }))
              .then(response => {
                JsLoadingOverlay.hide();;
                if (response.data.status != 'success') {
                  reject(response.data.message);
                  return;
                }
                resolve(response.data.message);
              })
              .catch(error => console.log(error));
          });
        },
        addLeadSettingsToAllSubZones(postData) {
          return new Promise((resolve, reject) => {
            JsLoadingOverlay.show({
                    'spinnerIcon': 'ball-clip-rotate',
                    "overlayOpacity": "0.6",
                    "spinnerSize": "2x",
                    "lockScroll": true,
                });
            axios.post('/settings/add_allto_lead_subaccounts', qs.stringify(postData, {
              parseArrays: false
            }))
              .then(response => {
                JsLoadingOverlay.hide();
                if (response.data.status != 'success') {
                  reject(response.data.message);
                  return;
                }
                resolve(response.data.genericBody);
              })
              .catch(error => console.log(error));
          });
        },
        fetchCustomRulesData() {
          Nprogress.start()
          axios.get("/settings/custom_rules/true")
            .then(response => {
              Nprogress.done()
              this.customRulesData = response.data.message;
              this.customRuleSampleFilePath = response.data.samplePath;
            })
            .catch(error => {
              if (error.response) {
                if (error.response.status == 403) {
                  window.location = "/dashboard";
                }
              }
            })
        },
        importCustomRuleModal(postData) {
            return new Promise((resolve, reject) => {
                JsLoadingOverlay.show({
                    'spinnerIcon': 'ball-clip-rotate',
                    "overlayOpacity": "0.6",
                    "spinnerSize": "2x",
                    "lockScroll": true,
                });
              axios.post('/settings/import_custom_rule_file', postData)
                .then(response => {
                  JsLoadingOverlay.hide();
                  if (response.data.status != 'success') {
                    reject(response.data.message);
                    return;
                  }
                  resolve(response.data);
                })
                .catch(error => console.log(error));
            });
          },
        addCustomRuleSubmit(postData) {
          return new Promise((resolve, reject) => {
            JsLoadingOverlay.show({
                    'spinnerIcon': 'ball-clip-rotate',
                    "overlayOpacity": "0.6",
                    "spinnerSize": "2x",
                    "lockScroll": true,
                });
            axios.post('/settings/add_edit_custom_rules', qs.stringify(postData, {
              parseArrays: false
            }))
              .then(response => {
                JsLoadingOverlay.hide();
                if (response.data.status != 'success') {
                  reject(response.data.message);
                  return;
                }
                if (postData.fieldId == "") {
                  if (Object.keys(this.customRulesData).length != 0) {
                    this.customRulesData.customRules.push(response.data.customRule);
                  }
                  resolve(response.data.genericBody);
                } else {
                  var index = "";
                  if (Object.keys(this.customRulesData).length != 0 && Object.keys(this.customRulesData.customRules).length != 0) {
                    for (var i = 0; i < this.customRulesData.customRules.length; i++) {
                      if (this.customRulesData.customRules[i].RequestAccessRule.id == response.data.customRule.RequestAccessRule.id) {
                        index = i;
                      }
                    }
                    this.customRulesData.customRules[index] = response.data.customRule;
                  }                  
                  resolve(response.data.genericBody1);
                }
              })
              .catch(error => console.log(error));
          });
        },
        deleteCustomRuleSubmit(postData) {
          return new Promise((resolve, reject) => {
            JsLoadingOverlay.show({
                    'spinnerIcon': 'ball-clip-rotate',
                    "overlayOpacity": "0.6",
                    "spinnerSize": "2x",
                    "lockScroll": true,
                });
            axios.post('/settings/delete_custom_rule', qs.stringify(postData, {
              parseArrays: false
            }))
              .then(response => {
                JsLoadingOverlay.hide();
                if (response.data.status != 'success') {
                  reject(response.data.message);
                  return;
                }
                var successData = {
                  id: postData.fieldId,
                }
                var index = "";
                if (Object.keys(this.customRulesData).length != 0 && Object.keys(this.customRulesData.customRules).length != 0) {
                  for (var i = 0; i < this.customRulesData.customRules.length; i++) {
                    if (this.customRulesData.customRules[i].RequestAccessRule.id == successData.id) {
                      index = i;
                    }
                  }
                  this.customRulesData.customRules.splice(index, 1)
                }                
                resolve(response.data.genericBody);
              })
              .catch(error => console.log(error));
          });
        },
        addCustomRulesToAllSubZones(postData) {
          return new Promise((resolve, reject) => {
            JsLoadingOverlay.show({
                    'spinnerIcon': 'ball-clip-rotate',
                    "overlayOpacity": "0.6",
                    "spinnerSize": "2x",
                    "lockScroll": true,
                });
            axios.post('/settings/add_custom_rules_to_all_accounts', qs.stringify(postData, {
              parseArrays: false
            }))
              .then(response => {
                JsLoadingOverlay.hide();
                if (response.data.status != 'success') {
                  reject(response.data.message);
                  return;
                }
                resolve(response.data.genericBody);
              })
              .catch(error => console.log(error));
          });
        },
        fetchUserFieldSettings() {
          Nprogress.start()
          axios.get("/settings/user_mandatory_fields/true")
            .then(response => {
              Nprogress.done()
              this.userFieldsData = response.data.message;
            })
            .catch(error => {
              if (error.response) {
                if (error.response.status == 403) {
                  window.location = "/dashboard";
                }
              }
            })
        },
        fetchtr069FieldSettings() {
          Nprogress.start()
          axios.get("/settings/tr069_mandatory_fields/true")
            .then(response => {
              Nprogress.done()
              this.tr069FieldsData = response.data.message;
            })
            .catch(error => {
              if (error.response) {
                if (error.response.status == 403) {
                  window.location = "/dashboard";
                }
              }
            })
        },
        userFieldSettingsSubmit(postData) {
          return new Promise((resolve, reject) => {
            JsLoadingOverlay.show({
                    'spinnerIcon': 'ball-clip-rotate',
                    "overlayOpacity": "0.6",
                    "spinnerSize": "2x",
                    "lockScroll": true,
                });
            axios.post('/settings/user_registration_submit', qs.stringify(postData, {
              parseArrays: false
            }))
              .then(response => {
                JsLoadingOverlay.hide();;
                if (response.data.status != 'success') {
                  reject(response.data.message);
                  return;
                }
                resolve(response.data.message);
              })
              .catch(error => console.log(error));
          });
        },
        tr069SettingsSubmit(postData) {
          return new Promise((resolve, reject) => {
            JsLoadingOverlay.show({
                    'spinnerIcon': 'ball-clip-rotate',
                    "overlayOpacity": "0.6",
                    "spinnerSize": "2x",
                    "lockScroll": true,
                });
            axios.post('/settings/tr069_registration_submit', qs.stringify(postData, {
              parseArrays: false
            }))
              .then(response => {
                JsLoadingOverlay.hide();;
                if (response.data.status != 'success') {
                  reject(response.data.message);
                  return;
                }
                resolve(response.data.message);
              })
              .catch(error => console.log(error));
          });
        },
        addUserSettingsToAllSubZones(postData) {
          return new Promise((resolve, reject) => {
            JsLoadingOverlay.show({
                    'spinnerIcon': 'ball-clip-rotate',
                    "overlayOpacity": "0.6",
                    "spinnerSize": "2x",
                    "lockScroll": true,
                });
            axios.post('/settings/add_all_users_save_fields', qs.stringify(postData, {
              parseArrays: false
            }))
              .then(response => {
                JsLoadingOverlay.hide();
                if (response.data.status != 'success') {
                  reject(response.data.message);
                  return;
                }
                resolve(response.data.genericBody);
              })
              .catch(error => console.log(error));
          });
        },
        addTr069SettingsToAllSubZones(postData) {
          return new Promise((resolve, reject) => {
            JsLoadingOverlay.show({
                    'spinnerIcon': 'ball-clip-rotate',
                    "overlayOpacity": "0.6",
                    "spinnerSize": "2x",
                    "lockScroll": true,
                });
            axios.post('/settings/add_all_tr069_save_fields', qs.stringify(postData, {
              parseArrays: false
            }))
              .then(response => {
                JsLoadingOverlay.hide();
                if (response.data.status != 'success') {
                  reject(response.data.message);
                  return;
                }
                resolve(response.data.genericBody);
              })
              .catch(error => console.log(error));
          });
        },
        fetchConsolidatedTemplateDetails() {
          Nprogress.start()
          axios.get("/settings/user_notifications_settings/true")
              .then(response => {
                  Nprogress.done()
                  if (response.data.status == "redirect") {
                      router.push(response.data.message);
                  } else {
                      var tData = {};
                      var parseData = response.data.message;
                      if (Object.keys(this.consolidatedTemplateList).length == 0) {
                          tData[response.data.accountId + '-consolidatedTemplateList'] = parseData;
                          this.consolidatedTemplateList = tData;
                      } else {
                          this.consolidatedTemplateList[ response.data.accountId + '-consolidatedTemplateList' ] = parseData;
                      }                      
                  }
              })
              .catch(error => console.log(error))
        },
        userNotificationsSubmit(postData) {
          return new Promise((resolve, reject) => {
            JsLoadingOverlay.show({
                    'spinnerIcon': 'ball-clip-rotate',
                    "overlayOpacity": "0.6",
                    "spinnerSize": "2x",
                    "lockScroll": true,
                });
            axios.post('/settings/user_notifications_submit', qs.stringify(postData, {
                parseArrays: false
            }))
                .then(response => {
                    JsLoadingOverlay.hide();
                    if (response.data.status != 'success') {
                        reject(response.data.message);
                        return;
                    }
                    resolve(response.data);
                })
                .catch(error => console.log(error));
          });
        },    
        addConsolidatedTemplatesToAllSubZones(postData) {
          return new Promise((resolve, reject) => {
            JsLoadingOverlay.show({
                    'spinnerIcon': 'ball-clip-rotate',
                    "overlayOpacity": "0.6",
                    "spinnerSize": "2x",
                    "lockScroll": true,
                });
            axios.post('/settings/add_consolidated_templates_to_all_accounts', qs.stringify(postData, {
                parseArrays: false
            }))
                .then(response => {
                    JsLoadingOverlay.hide();
                    if (response.data.status != 'success') {
                        reject(response.data.message);
                        return;
                    }
                    resolve(response.data.genericBody);
                })
                .catch(error => console.log(error));
          });
        },
        fetchAdditionalFieldSettings() {
          Nprogress.start()
          axios.get("/settings/additional_fields/true")
            .then(response => {
              Nprogress.done()
              this.additionalFieldsData = response.data.message;
              this.additionalFieldRulesData = response.data.message.additionalFieldRules;              
            })
            .catch(error => {
              if (error.response) {
                if (error.response.status == 403) {
                  window.location = "/dashboard";
                }
              }
            })
        },
        deleteMetaFieldOption(postData) {
          return new Promise((resolve, reject) => {
            JsLoadingOverlay.show({
                    'spinnerIcon': 'ball-clip-rotate',
                    "overlayOpacity": "0.6",
                    "spinnerSize": "2x",
                    "lockScroll": true,
                });
            axios.post('/settings/del_metaField_option', qs.stringify(postData, {
              parseArrays: false
            }))
              .then(response => {
                JsLoadingOverlay.hide();
                if (response.data.status != 'success') {
                  reject(response.data.message);
                  return;
                }
                this.updateAdditionalFieldsData(response.data.metaFieldNameValues)
                resolve(response.data.genericBody);
              })
              .catch(error => console.log(error));
          });
        },
        editAdditionalFieldsData(postData) {
          return new Promise((resolve, reject) => {
            JsLoadingOverlay.show({
                    'spinnerIcon': 'ball-clip-rotate',
                    "overlayOpacity": "0.6",
                    "spinnerSize": "2x",
                    "lockScroll": true,
                });
            axios.post('/settings/edit_meta_fields', qs.stringify(postData, {
              parseArrays: false
            }))
              .then(response => {
                JsLoadingOverlay.hide();
                if (response.data.status != 'success') {
                  reject(response.data.message);
                  return;
                }
                resolve(response.data);
              })
              .catch(error => console.log(error));
          });
        },
        addMetaFieldOption(postData) {
          return new Promise((resolve, reject) => {
            JsLoadingOverlay.show({
                    'spinnerIcon': 'ball-clip-rotate',
                    "overlayOpacity": "0.6",
                    "spinnerSize": "2x",
                    "lockScroll": true,
                });
            axios.post('/settings/add_metaField_option', qs.stringify(postData, {
              parseArrays: false
            }))
              .then(response => {
                JsLoadingOverlay.hide();
                if (response.data.status != 'success') {
                  reject(response.data.message);
                  return;
                }
                this.updateAdditionalFieldsData(response.data.metaFieldNameValues)
                resolve(response.data.genericBody);
              })
              .catch(error => console.log(error));
          });
        },
        moveMetaFieldUp(postData) {
          return new Promise((resolve, reject) => {
            JsLoadingOverlay.show({
                    'spinnerIcon': 'ball-clip-rotate',
                    "overlayOpacity": "0.6",
                    "spinnerSize": "2x",
                    "lockScroll": true,
                });
            axios.post('/settings/move_meta_field_up', qs.stringify(postData, {
              parseArrays: false
            }))
              .then(response => {
                JsLoadingOverlay.hide();
                if (response.data.status != 'success') {
                  reject(response.data.message);
                  return;
                }
                this.updateAdditionalFieldsData(response.data.metaFieldNameValues)
                resolve(response.data.genericBody);
              })
              .catch(error => console.log(error));
          });
        },
        moveMetaFieldDown(postData) {
          return new Promise((resolve, reject) => {
            JsLoadingOverlay.show({
                    'spinnerIcon': 'ball-clip-rotate',
                    "overlayOpacity": "0.6",
                    "spinnerSize": "2x",
                    "lockScroll": true,
                });
            axios.post('/settings/move_meta_field_down', qs.stringify(postData, {
              parseArrays: false
            }))
              .then(response => {
                JsLoadingOverlay.hide();
                if (response.data.status != 'success') {
                  reject(response.data.message);
                  return;
                }
                this.updateAdditionalFieldsData(response.data.metaFieldNameValues)
                resolve(response.data.genericBody);
              })
              .catch(error => console.log(error));
          });
        },
        deleteMetaFieldSubmit(postData) {
          return new Promise((resolve, reject) => {
            JsLoadingOverlay.show({
                    'spinnerIcon': 'ball-clip-rotate',
                    "overlayOpacity": "0.6",
                    "spinnerSize": "2x",
                    "lockScroll": true,
                });
            axios.post('/settings/del_meta_field', qs.stringify(postData, {
              parseArrays: false
            }))
              .then(response => {
                JsLoadingOverlay.hide();
                if (response.data.status != 'success') {
                  reject(response.data.message);
                  return;
                }
                this.updateAdditionalFieldsData(response.data.metaFieldNameValues)
                resolve(response.data.genericBody);
              })
              .catch(error => console.log(error));
          });
        },
        createMetaFieldFormSubmit(postData) {
          return new Promise((resolve, reject) => {
            JsLoadingOverlay.show({
                    'spinnerIcon': 'ball-clip-rotate',
                    "overlayOpacity": "0.6",
                    "spinnerSize": "2x",
                    "lockScroll": true,
                });
            axios.post('/settings/create_meta_field', qs.stringify(postData, {
              parseArrays: false
            }))
              .then(response => {
                JsLoadingOverlay.hide();
                if (response.data.status != 'success') {
                  reject(response.data.message);
                  return;
                }
                this.updateAdditionalFieldsData(response.data.metaFieldNameValues)
                resolve(response.data.genericBody);
              })
              .catch(error => console.log(error));
          });
        },
        addAdditionalSettingsToAllSubZones(postData) {
          return new Promise((resolve, reject) => {
            JsLoadingOverlay.show({
                    'spinnerIcon': 'ball-clip-rotate',
                    "overlayOpacity": "0.6",
                    "spinnerSize": "2x",
                    "lockScroll": true,
                });
            axios.post('/settings/add_additional_fields_subacc', qs.stringify(postData, {
              parseArrays: false
            }))
              .then(response => {
                JsLoadingOverlay.hide();
                if (response.data.status != 'success') {
                  reject(response.data.message);
                  return;
                }
                resolve(response.data.genericBody);
              })
              .catch(error => console.log(error));
          });
        },
        updateAdditionalFieldsData(settingsData) {
          this.additionalFieldsData['metaFieldNameValues'] = settingsData;
        },
        addAdditionalFieldRuleSubmit(postData) {
          return new Promise((resolve, reject) => {
            JsLoadingOverlay.show({
                    'spinnerIcon': 'ball-clip-rotate',
                    "overlayOpacity": "0.6",
                    "spinnerSize": "2x",
                    "lockScroll": true,
                });
            axios.post('/settings/add_edit_additional_field_rules', qs.stringify(postData, {
              parseArrays: false
            }))
              .then(response => {
                JsLoadingOverlay.hide();
                if (response.data.status != 'success') {
                  reject(response.data.message);
                  return;
                }
                if (postData.fieldId == "") {
                  if (Object.keys(this.additionalFieldRulesData).length != 0) {
                    this.additionalFieldRulesData.push(response.data.additionalFieldRule);
                  }                  
                  resolve(response.data.genericBody);
                } else {
                  var index = "";
                  if (Object.keys(this.additionalFieldRulesData).length != 0 && Object.keys(this.additionalFieldRulesData).length != 0) {
                    for (var i = 0; i < this.additionalFieldRulesData.length; i++) {
                      if (this.additionalFieldRulesData[i].RequestAccessRule.id == response.data.additionalFieldRule.RequestAccessRule.id) {
                        index = i;
                      }
                    }
                    this.additionalFieldRulesData[index] = response.data.additionalFieldRule;
                  }                  
                  resolve(response.data.genericBody1);
                }
              })
              .catch(error => console.log(error));
          });
        },
        deleteAdditionalFieldRuleSubmit(postData) {
          return new Promise((resolve, reject) => {
            JsLoadingOverlay.show({
                    'spinnerIcon': 'ball-clip-rotate',
                    "overlayOpacity": "0.6",
                    "spinnerSize": "2x",
                    "lockScroll": true,
                });
            axios.post('/settings/delete_additional_field_rule', qs.stringify(postData, {
              parseArrays: false
            }))
              .then(response => {
                JsLoadingOverlay.hide();
                if (response.data.status != 'success') {
                  reject(response.data.message);
                  return;
                }
                var successData = {
                  id: postData.fieldId,
                }
                var index = "";
                if (Object.keys(this.additionalFieldRulesData).length != 0 && Object.keys(this.additionalFieldRulesData).length != 0) {
                  for (var i = 0; i < this.additionalFieldRulesData.length; i++) {
                    if (this.additionalFieldRulesData[i].RequestAccessRule.id == successData.id) {
                      index = i;
                    }
                  }
                  this.additionalFieldRulesData.splice(index, 1)
                }                
                resolve(response.data.genericBody);
              })
              .catch(error => console.log(error));
          });
        },
        additionalFieldMappingValueSubmit(postData) {
          return new Promise((resolve, reject) => {
            JsLoadingOverlay.show({
                    'spinnerIcon': 'ball-clip-rotate',
                    "overlayOpacity": "0.6",
                    "spinnerSize": "2x",
                    "lockScroll": true,
                });
            axios.post('/settings/additional_field_mapping_value_submit', qs.stringify(postData, {
              parseArrays: false
            }))
              .then(response => {
                JsLoadingOverlay.hide();
                if (response.data.status != 'success') {
                  reject(response.data.message);
                  return;
                }
                resolve(response.data);
              })
              .catch(error => console.log(error));
          });
        },
        fetchDashboardDetails() {
          Nprogress.start()
          axios.get("/settings/dashboard_configuration/true")
            .then(response => {
              Nprogress.done()              
              this.dashConfigDetails = response.data.message;
            })
            .catch(error => {
              if (error.response) {
                if (error.response.status == 403) {
                  window.location = "/dashboard";
                }
              }
            })
        },
        addDashboardToAllSubZones(postData) {
          return new Promise((resolve, reject) => {
            JsLoadingOverlay.show({
                    'spinnerIcon': 'ball-clip-rotate',
                    "overlayOpacity": "0.6",
                    "spinnerSize": "2x",
                    "lockScroll": true,
                });
            axios.post('/settings/add_dashbord_configuration_toall_subaccounts', qs.stringify(postData, {
              parseArrays: false
            }))
              .then(response => {
                JsLoadingOverlay.hide();
                if (response.data.status != 'success') {
                  reject(response.data.message);
                  return;
                }
                resolve(response.data.genericBody);
              })
              .catch(error => console.log(error));
          });
        },
        dashboardConfigurationSubmit(postData) {
          return new Promise((resolve, reject) => {
            JsLoadingOverlay.show({
                    'spinnerIcon': 'ball-clip-rotate',
                    "overlayOpacity": "0.6",
                    "spinnerSize": "2x",
                    "lockScroll": true,
                });
            axios.post('/settings/dashboard_configuration_submit', qs.stringify(postData, {
              parseArrays: false
            }))
              .then(response => {
                JsLoadingOverlay.hide();;
                if (response.data.status != 'success') {
                  reject(response.data.message);
                  return;
                }
                resolve(response.data.message);
              })
              .catch(error => console.log(error));
          });
        },
        fetchCustPortURLDetails() {
          Nprogress.start()
          axios.get("/settings/customer_portal_url/true")
            .then(response => {
              Nprogress.done()
              this.custPortURLConfigDetails = response.data.message;
            })
            .catch(error => {
              if (error.response) {
                if (error.response.status == 403) {
                  window.location = "/dashboard";
                }
              }
            })
        },
        customerPortalURLSubmit(postData) {
          return new Promise((resolve, reject) => {
            JsLoadingOverlay.show({
                    'spinnerIcon': 'ball-clip-rotate',
                    "overlayOpacity": "0.6",
                    "spinnerSize": "2x",
                    "lockScroll": true,
                });
            axios.post('/settings/customer_portal_url_submit', qs.stringify(postData, {
              parseArrays: false
            }))
              .then(response => {
                JsLoadingOverlay.hide();;
                if (response.data.status != 'success') {
                  reject(response.data.message);
                  return;
                }
                resolve(response.data.message);
              })
              .catch(error => console.log(error));
          });
        },
        fetchsmsManagementData() {
          Nprogress.start()
          axios.get("/settings/sms_management/true")
            .then(response => {
              Nprogress.done()
              this.smsManagementData = response.data.message;
            })
            .catch(error => {
              if (error.response) {
                if (error.response.status == 403) {
                  window.location = "/dashboard";
                }
              }
            })
        },
        addSmsLimitSubmit(postData) {
          return new Promise((resolve, reject) => {
            JsLoadingOverlay.show({
                    'spinnerIcon': 'ball-clip-rotate',
                    "overlayOpacity": "0.6",
                    "spinnerSize": "2x",
                    "lockScroll": true,
                });
            axios.post('/settings/sms_management_submit', qs.stringify(postData, {
              parseArrays: false
            }))
              .then(response => {
                JsLoadingOverlay.hide();;
                if (response.data.status != 'success') {
                  reject(response.data.message);
                  return;
                }
                if (Object.keys(this.smsManagementData).length != 0) {
                  for (var i = 0; i < this.smsManagementData.smsDetails.length; i++) {
                    if (this.smsManagementData.smsDetails[i].accountId == response.data.addedSmsDetail.account) {
                      this.smsManagementData.smsDetails[i]['totalSmsCount'] = response.data.addedSmsDetail.smsLimit;
                      this.smsManagementData.smsDetails[i]['unlimitedSMS'] = response.data.addedSmsDetail.unlimitedSMS;
                    }
                  }
                }                
                resolve(response.data.genericBody);
              })
              .catch(error => console.log(error));
          });
        },
        fetchPaymentCollectDetails() {
          Nprogress.start()
          axios.get("/settings/payment_collection_settings/true")
            .then(response => {
              Nprogress.done()
              this.payConfigDetails = response.data.message;
            })
            .catch(error => {
              if (error.response) {
                if (error.response.status == 403) {
                  window.location = "/dashboard";
                }
              }
            })
        },
        addPaymentcollectToAllSubZones(postData) {
          return new Promise((resolve, reject) => {
            JsLoadingOverlay.show({
                    'spinnerIcon': 'ball-clip-rotate',
                    "overlayOpacity": "0.6",
                    "spinnerSize": "2x",
                    "lockScroll": true,
                });
            axios.post('/settings/add_payment_collection_to_subAcc', qs.stringify(postData, {
              parseArrays: false
            }))
              .then(response => {
                JsLoadingOverlay.hide();
                if (response.data.status != 'success') {
                  reject(response.data.message);
                  return;
                }
                resolve(response.data.genericBody);
              })
              .catch(error => console.log(error));
          });
        },
        paymentcollectConfigurationSubmit(postData) {
          return new Promise((resolve, reject) => {
            JsLoadingOverlay.show({
                    'spinnerIcon': 'ball-clip-rotate',
                    "overlayOpacity": "0.6",
                    "spinnerSize": "2x",
                    "lockScroll": true,
                });
            axios.post('/settings/payment_collection_settings_submit', qs.stringify(postData, {
              parseArrays: false
            }))
              .then(response => {
                JsLoadingOverlay.hide();;
                if (response.data.status != 'success') {
                  reject(response.data.message);
                  return;
                }
                resolve(response.data.message);
              })
              .catch(error => console.log(error));
          });
        },
        fetchTallyDetails() {
          Nprogress.start()
          axios.get("/settings/tally_informations/true")
            .then(response => {
              Nprogress.done()
              this.tallyConfigDetails = response.data.message;
            })
            .catch(error => {
              if (error.response) {
                if (error.response.status == 403) {
                  window.location = "/dashboard";
                }
              }
            })
        },
        addTallyToAllSubZones(postData) {
          return new Promise((resolve, reject) => {
            JsLoadingOverlay.show({
                    'spinnerIcon': 'ball-clip-rotate',
                    "overlayOpacity": "0.6",
                    "spinnerSize": "2x",
                    "lockScroll": true,
                });
            axios.post('/settings/add_tally_information_to_subAcc', qs.stringify(postData, {
              parseArrays: false
            }))
              .then(response => {
                JsLoadingOverlay.hide();
                if (response.data.status != 'success') {
                  reject(response.data.message);
                  return;
                }
                resolve(response.data.genericBody);
              })
              .catch(error => console.log(error));
          });
        },
        tallyConfigurationSubmit(postData) {
          return new Promise((resolve, reject) => {
            JsLoadingOverlay.show({
                    'spinnerIcon': 'ball-clip-rotate',
                    "overlayOpacity": "0.6",
                    "spinnerSize": "2x",
                    "lockScroll": true,
                });
            axios.post('/settings/tally_informations_submit', qs.stringify(postData, {
              parseArrays: false
            }))
              .then(response => {
                JsLoadingOverlay.hide();;
                if (response.data.status != 'success') {
                  reject(response.data.message);
                  return;
                }
                resolve(response.data.message);
              })
              .catch(error => console.log(error));
          });
        },
        fetchmoveAccountsData() {
          Nprogress.start()
          axios.get("/settings/move_accounts/true")
            .then(response => {
              Nprogress.done()
              this.moveAccountsData = response.data.message;
            })
            .catch(error => {
              if (error.response) {
                if (error.response.status == 403) {
                  window.location = "/dashboard";
                }
              }
            })
        },
        moveAccountsSubmit(postData) {
          return new Promise((resolve, reject) => {
            JsLoadingOverlay.show({
                    'spinnerIcon': 'ball-clip-rotate',
                    "overlayOpacity": "0.6",
                    "spinnerSize": "2x",
                    "lockScroll": true,
                });
            axios.post('/settings/move_accounts_submit', qs.stringify(postData, {
              parseArrays: false
            }))
              .then(response => {
                JsLoadingOverlay.hide();;
                if (response.data.status != 'success') {
                  reject(response.data.message);
                  return;
                }
                resolve(response.data.message);
              })
              .catch(error => console.log(error));
          });
        },
        fetchAdminWithAccessData() {
          Nprogress.start()
          axios.get("/settings/admins_with_access/true")
            .then(response => {
              Nprogress.done()
              this.adminWithAccessDetails = response.data.message;
            })
            .catch(error => {
              if (error.response) {
                if (error.response.status == 403) {
                  window.location = "/dashboard";
                }
              }
            })
        },
        fetchWorkHrs(authData) {
          Nprogress.start()
          axios.get("/settings/enable_working_hours/" + authData.action + "/" + authData.adminId)
            .then(response => {
              Nprogress.done()
              this.workhrsData = response.data.response;
            })
            .catch(error => console.log(error))
        },
        saveWrkData(postData) {
          return new Promise((resolve, reject) => {
            JsLoadingOverlay.show({
                    'spinnerIcon': 'ball-clip-rotate',
                    "overlayOpacity": "0.6",
                    "spinnerSize": "2x",
                    "lockScroll": true,
                });
            axios.post('/settings/enable_working_hours/' + postData.action + "/" + postData.adminId, qs.stringify(postData, {
              parseArrays: false
            }))
              .then(response => {
                JsLoadingOverlay.hide();
                if (response.data.status != 'success') {
                  reject(response.data.message);
                  return;
                }
      
                resolve(response.data);
              })
              .catch(error => console.log(error));
          });
        },
        fetchsmsManagementCloudData() {
          Nprogress.start()
          axios.get("/settings/sms_managements/true")
            .then(response => {
              Nprogress.done()
              this.smsManagementCloudData = response.data.message;
            })
            .catch(error => {
              if (error.response) {
                if (error.response.status == 403) {
                  window.location = "/dashboard";
                }
              }
            })
        },
        smsManagementCloudSubmit(postData) {
          return new Promise((resolve, reject) => {
            JsLoadingOverlay.show({
                    'spinnerIcon': 'ball-clip-rotate',
                    "overlayOpacity": "0.6",
                    "spinnerSize": "2x",
                    "lockScroll": true,
                });
            axios.post('/settings/sms_configuration_submit', qs.stringify(postData, {
              parseArrays: false
            }))
              .then(response => {
                JsLoadingOverlay.hide();;
                if (response.data.status != 'success') {
                  reject(response.data.message);
                  return;
                }
                resolve(response.data.genericBody);
              })
              .catch(error => console.log(error));
          });
        },
        fetchPaymentGatewaySettings() {
          Nprogress.start()
          axios.get("/settings/tag_payment_gateway/true")
            .then(response => {
              Nprogress.done()
              this.paymentGatewayData = response.data.message;
            })
            .catch(error => {
              if (error.response) {
                if (error.response.status == 403) {
                  window.location = "/dashboard";
                }
              }
            })
        },
        paymentGatewaySettingsSubmit(postData) {
          return new Promise((resolve, reject) => {
            JsLoadingOverlay.show({
                    'spinnerIcon': 'ball-clip-rotate',
                    "overlayOpacity": "0.6",
                    "spinnerSize": "2x",
                    "lockScroll": true,
                });
            axios.post('/settings/tag_payment_gateway_settings_submit/true', qs.stringify(postData, {
              parseArrays: false
            }))
              .then(response => {
                JsLoadingOverlay.hide();;
                if (response.data.status != 'success') {
                  reject(response.data.message);
                  return;
                }
                resolve(response.data.message);
              })
              .catch(error => console.log(error));
          });
        },
        fetchPMSSettingsDetails() {
          Nprogress.start()
          axios.get("/settings/pms_settings/true")
            .then(response => {
              Nprogress.done()
              this.pmsSettingsDetails = response.data.message;
            })
            .catch(error => {
              if (error.response) {
                if (error.response.status == 403) {
                  window.location = "/dashboard";
                }
              }
            })
        },
        pmsSettingsSubmit(postData) {
          return new Promise((resolve, reject) => {
            JsLoadingOverlay.show({
                    'spinnerIcon': 'ball-clip-rotate',
                    "overlayOpacity": "0.6",
                    "spinnerSize": "2x",
                    "lockScroll": true,
                });
            axios.post('/settings/pms_settings_submit', qs.stringify(postData, {
              parseArrays: false
            }))
              .then(response => {
                JsLoadingOverlay.hide();;
                if (response.data.status != 'success') {
                  reject(response.data.message);
                  return;
                }
                resolve(response.data.message);
              })
              .catch(error => console.log(error));
          });
        },
        fetchfranchiseSettingsData() {
          Nprogress.start()
          axios.get("/settings/franchise_settings/true")
            .then(response => {
              Nprogress.done()
              this.franchiseSettingsDetails = response.data.message;
            })
            .catch(error => {
              if (error.response) {
                if (error.response.status == 403) {
                  window.location = "/dashboard";
                }
              }
            })
        },
        addNewTaxSubmit(postData) {
          return new Promise((resolve, reject) => {
            JsLoadingOverlay.show({
                    'spinnerIcon': 'ball-clip-rotate',
                    "overlayOpacity": "0.6",
                    "spinnerSize": "2x",
                    "lockScroll": true,
                });
            axios.post('/franchise_management/add_tax_field/true', qs.stringify(postData, {
              parseArrays: false
            }))
              .then(response => {
                JsLoadingOverlay.hide();
                if (response.data.status != 'success') {
                  reject(response.data.message);
                  return;
                }
                resolve(response.data.message);
              })
              .catch(error => console.log(error));
          });
        },
        deleteAdditionalTaxesSubmit(postData) {
          return new Promise((resolve, reject) => {
            JsLoadingOverlay.show({
                    'spinnerIcon': 'ball-clip-rotate',
                    "overlayOpacity": "0.6",
                    "spinnerSize": "2x",
                    "lockScroll": true,
                });
            axios.post('/franchise_management/delete_tax_field/true', qs.stringify(postData, {
              parseArrays: false
            }))
              .then(response => {
                JsLoadingOverlay.hide();
                if (response.data.status != 'Success') {
                  reject(response.data.message);
                  return;
                }
                resolve(response.data.message);
              })
              .catch(error => console.log(error));
          });
        },
        franchiseSettingsSubmit(postData) {
          return new Promise((resolve, reject) => {
            JsLoadingOverlay.show({
                    'spinnerIcon': 'ball-clip-rotate',
                    "overlayOpacity": "0.6",
                    "spinnerSize": "2x",
                    "lockScroll": true,
                });
            axios.post('/settings/franchise_settings_submit', qs.stringify(postData, {
              parseArrays: false
            }))
              .then(response => {
                JsLoadingOverlay.hide();;
                if (response.data.status != 'success' && response.data.status != 'Success') {
                  var res = {};
                  if (response.data.status == 'profile name error') {
                    res.profileGroups = response.data.message;
                    res.type = 'profileNameError'
                  } else {
                    res.profileGroups = response.data.message;
                    res.type = 'error'
                  }
                  reject(res);
                  return;
                }
                resolve(response.data.message);
              })
              .catch(error => console.log(error));
          });
        },
        fetchLinkedInData() {
          Nprogress.start()
          axios.get("/settings/linkedin_settings/true")
            .then(response => {
              Nprogress.done()
              this.linkedInData = response.data.message;
            })
            .catch(error => {
              if (error.response) {
                if (error.response.status == 403) {
                  window.location = "/dashboard";
                }
              }
            })
        },
      
        fetchTwitterData() {
          Nprogress.start()
          axios.get("/settings/twitter_settings/true")
            .then(response => {
              Nprogress.done()
              this.twitterData = response.data.message;
            })
            .catch(error => {
              if (error.response) {
                if (error.response.status == 403) {
                  window.location = "/dashboard";
                }
              }
            })
        },
      
        fetchFacebookData() {
          Nprogress.start()
          axios.get("/settings/facebook_settings/true")
            .then(response => {
              Nprogress.done()
              this.facebookData = response.data.message;
            })
            .catch(error => {
              if (error.response) {
                if (error.response.status == 403) {
                  window.location = "/dashboard";
                }
              }
            })
        },
      
        fetchInstgaramData() {
          Nprogress.start()
          axios.get("/settings/instagram_settings/true")
            .then(response => {
              Nprogress.done()
              this.instagramData = response.data.message;
            })
            .catch(error => {
              if (error.response) {
                if (error.response.status == 403) {
                  window.location = "/dashboard";
                }
              }
            })
        },
      
        fetchGoogleData() {
          Nprogress.start()
          axios.get("/settings/google_settings/true")
            .then(response => {
              Nprogress.done()
              this.googleplusData = response.data.message;
            })
            .catch(error => {
              if (error.response) {
                if (error.response.status == 403) {
                  window.location = "/dashboard";
                }
              }
            })
        },
        saveSocialSettings(postData) {
          return new Promise((resolve, reject) => {
            JsLoadingOverlay.show({
                    'spinnerIcon': 'ball-clip-rotate',
                    "overlayOpacity": "0.6",
                    "spinnerSize": "2x",
                    "lockScroll": true,
                });
            if(postData.funcName == 'linkedInSettingsSubmit') {
              axios.post('/settings/linkedin_settings_submit', qs.stringify(postData, {
                parseArrays: false
              }))
                .then(response => {
                  JsLoadingOverlay.hide();;
                  if (response.data.status != 'success') {
                    reject(response.data.message);
                    return;
                  }
                  resolve(response.data.settingsServerSuccess);
                })
                .catch(error => console.log(error));
            } else if(postData.funcName == 'twitterSettingsSubmit') {
              axios.post('/settings/twitter_settings_submit', qs.stringify(postData, {
                parseArrays: false
              }))
                .then(response => {
                  JsLoadingOverlay.hide();;
                  if (response.data.status != 'success') {
                    reject(response.data.message);
                    return;
                  }
                  resolve(response.data.settingsServerSuccess);
                })
                .catch(error => console.log(error));
            } else if(postData.funcName == 'facebookSettingsSubmit') {
              axios.post('/settings/facebook_settings_submit', qs.stringify(postData, {
                parseArrays: false
              }))
                .then(response => {
                  JsLoadingOverlay.hide();;
                  if (response.data.status != 'success') {
                    reject(response.data.message);
                    return;
                  }
                  resolve(response.data.settingsServerSuccess);
                })
                .catch(error => console.log(error));
            } else if(postData.funcName == 'instaSettingsSubmit') {
              axios.post('/settings/instagram_settings_submit', qs.stringify(postData, {
                parseArrays: false
              }))
                .then(response => {
                  JsLoadingOverlay.hide();;
                  if (response.data.status != 'success') {
                    reject(response.data.message);
                    return;
                  }
                  resolve(response.data.settingsServerSuccess);
                })
                .catch(error => console.log(error));
            } else if(postData.funcName == 'googleSettingsSubmit') {
              axios.post('/settings/google_settings_submit', qs.stringify(postData, {
                parseArrays: false
              }))
                .then(response => {
                  JsLoadingOverlay.hide();;
                  if (response.data.status != 'success') {
                    reject(response.data.message);
                    return;
                  }
                  resolve(response.data.settingsServerSuccess);
                })
                .catch(error => console.log(error));
            }
          });
        },
        fetchPaymentGatewayZone(authData) {
          Nprogress.start()
          axios.get("/dashboard/zone_tag_payment_gateway/" + authData + "/true")
            .then(response => {
              Nprogress.done()
              this.paymentGatewayData = response.data.message;
            })
            .catch(error => {
              if (error.response) {
                if (error.response.status == 403) {
                  window.location = "/dashboard";
                }
              }
            })
        },
        defaultPackageSettingsSubmit(postData) {
          return new Promise((resolve, reject) => {
            JsLoadingOverlay.show({
                    'spinnerIcon': 'ball-clip-rotate',
                    "overlayOpacity": "0.6",
                    "spinnerSize": "2x",
                    "lockScroll": true,
                });
            axios.post('/settings/default_package_settings_submit', qs.stringify(postData, {
              parseArrays: false
            }))
              .then(response => {
                JsLoadingOverlay.hide();;
                if (response.data.status != 'success') {
                  var res = {};
                  res.profileGroups = response.data.message;
                  res.type = 'error'
                  reject(res);
                  return;
                }
                resolve(response.data.message);
              })
              .catch(error => console.log(error));
          });
        },
        addDefaultPackageSettingsToAllSubZones(postData) {
          return new Promise((resolve, reject) => {
            JsLoadingOverlay.show({
                    'spinnerIcon': 'ball-clip-rotate',
                    "overlayOpacity": "0.6",
                    "spinnerSize": "2x",
                    "lockScroll": true,
                });
            axios.post('/settings/add_defalt_package_settings_to_all_subaccounts', qs.stringify(postData, {
              parseArrays: false
            }))
              .then(response => {
                JsLoadingOverlay.hide();
                if (response.data.status != 'success') {
                  reject(response.data.message);
                  return;
                }
                resolve(response.data.genericBody);
              })
              .catch(error => console.log(error));
          });
        },
        fetchAppRunTimeData(authData) {
          Nprogress.start()
          axios.get("/settings/app_runtime/true")
            .then(response => {
              Nprogress.done()
             // commit('SET_APP_RUNTIME_DATA', response.data)
              this.appRunTimeDetails=response.data.message;
            })
            .catch(error => {
              if (error.response) {
                if (error.response.status == 403) {
                  window.location = "/dashboard";
                }
              }
          })
        },
        addAssignTasksFn(postData) {
          return new Promise((resolve, reject) => {
              axios
                  .post("/settings/admin_view_assignedall_tasks_settings", qs.stringify(postData, {
                      parseArrays: false
                  }))
                  .then(response => {
                      resolve(response.data);
                  })
                  .catch(error => console.log(error));
          });
        },
        deleteAssignedTaskAdmin(postData) {
          return new Promise((resolve, reject) => {
              axios
                  .post("/settings/delete_admin_view_assignedall_task_settings", qs.stringify(postData, {
                      parseArrays: false
                  }))
                  .then(response => {
                      resolve(response.data);
                  })
                  .catch(error => console.log(error));
          });
        }
    }
})